import { Maybe } from 'graphql/jsutils/Maybe'

import { mapAsset } from './mappers'

import { HeroHeader, HeroHeaderProps } from '#app/components/header/hero-header'
import {
  HeroHeader as HeroHeaderEntry,
  Link,
} from '#app/graphql/@generated/api'
import { getValidatedProperty, isValidProperty } from '#app/utils/shared'
import { BadgeAttributes, LinkAttributes } from '#types/shared'

export function CsHeroHeader({ entry }: { entry: HeroHeaderEntry }) {
  if (!entry) return null

  const mapLink = (
    link: Maybe<Link> | undefined,
  ): LinkAttributes | undefined =>
    link ? { title: link.title || '', href: link.href || '' } : undefined

  const mapHeroHeaderToProps = (entry: HeroHeaderEntry): HeroHeaderProps => {
    const {
      title,
      subtitle,
      badge,
      primary_link,
      secondary_link,
      show_background_image,
      banner_imageConnection,
      content_placement,
    } = entry

    return {
      title: title ?? '',
      subtitle: subtitle ?? '',
      primaryLink: mapLink(primary_link),
      secondaryLink: mapLink(secondary_link),
      showBackgroundImage: !!show_background_image,
      bannerImage: mapAsset(banner_imageConnection?.edges?.[0]),
      contentPlacement: getValidatedProperty(
        content_placement,
        'center',
        isValidProperty<HeroHeaderProps['contentPlacement']>,
      ),
      badge: {
        text: badge?.text || '',
        variant: getValidatedProperty(
          badge?.variant,
          'primary',
          isValidProperty<BadgeAttributes['variant']>,
        ),
      },
    }
  }

  return <HeroHeader {...mapHeroHeaderToProps(entry)} />
}
