import { mapAsset } from './mappers'

import {
  AutoScrollCarousel,
  AutoScrollCarouselProps,
} from '#app/components/auto-scroll-carousel/auto-scroll-carousel'
import { AutoScrollCarousel as AutoScrollCarouselEntry } from '#app/graphql/@generated/api'

const transformEntryToProps = (
  entry: AutoScrollCarouselEntry,
): AutoScrollCarouselProps => {
  return {
    title: entry.title ?? '',
    logos: (entry.logosConnection?.edges ?? []).map(mapAsset),
    testimonials:
      entry.testimonials?.map(testimonial => ({
        quote: testimonial?.quote ?? '',
        name: testimonial?.name ?? '',
        company: testimonial?.company ?? '',
        image: mapAsset(testimonial?.imageConnection?.edges?.[0]),
      })) ?? [],
  }
}

export function CsAutoScrollCarousel({
  entry,
}: {
  entry: AutoScrollCarouselEntry
}) {
  return <AutoScrollCarousel {...transformEntryToProps(entry)} />
}
