import {
  CoreNumbers,
  CoreNumbersProps,
} from '#app/components/core-numbers/core-numbers.tsx'
import { type CoreNumbers as CoreNumbersEntry } from '#app/graphql/@generated/api.ts'

const mapEntryToProps = (entry: CoreNumbersEntry): CoreNumbersProps => ({
  items:
    entry?.items?.map(item => ({
      number: item?.number?.toString() ?? '',
      description: item?.description ?? '',
      variation: item?.variation ?? '%',
    })) ?? [],
})

export const CsCoreNumbers = ({ entry }: { entry: CoreNumbersEntry }) => (
  <CoreNumbers {...mapEntryToProps(entry)} />
)
