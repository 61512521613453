import { IconName } from '@datasnipper/icons'

import {
  FeatureSection,
  FeatureSectionProps,
} from '#app/components/feature-section/feature-section'
import { FeatureSection as FeatureSectionEntry } from '#app/graphql/@generated/api'

export const CsFeatureSection = (entry: FeatureSectionEntry) => {
  if (!entry) return null

  const mapFeatureSectionToProps = (
    entry: FeatureSectionEntry,
  ): FeatureSectionProps => ({
    title: entry.title ?? '',
    subtitle: entry.subtitle ?? '',
    link: {
      title: entry.link?.title ?? '',
      href: entry.link?.href ?? '',
    },
    features:
      entry.feature_card_props?.map(feature => ({
        title: feature?.title ?? '',
        description: feature?.description ?? '',
        icon: (feature?.icon as IconName) ?? '',
      })) ?? [],
  })

  return <FeatureSection {...mapFeatureSectionToProps(entry)} />
}
