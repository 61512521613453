import { Hero, HeroProps } from '#app/components/hero/hero'
import { Hero as HeroEntry } from '#app/graphql/@generated/api'

export function CsHero({ entry }: { entry: HeroEntry }) {
  if (!entry) return null

  const mapHeroToProps = (entry: HeroEntry): HeroProps => ({
    title: entry.title || '',
    subtitle: entry.subtitle || '',
  })

  return <Hero {...mapHeroToProps(entry)} />
}
