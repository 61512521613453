import { cn } from '@datasnipper/utils/cn'

import { LinkButton } from '../link-button/link-button'

import { backgroundClasses } from '#app/constants/shared'
import { BackgroundClass, LinkType } from '#types/shared'

export type BannerProps = {
  title: string
  image?: {
    src: string
    alt: string
  }
  body?: string
  link?: LinkType
  imagePosition?: 'right' | 'left'
  background?: BackgroundClass
  className?: string
}

export const Banner = ({
  image,
  title,
  body,
  link,
  imagePosition = 'right',
  background = 'white',
  className,
}: BannerProps) => (
  <section
    aria-labelledby="region-banner"
    className={cn(
      'flex justify-center',
      backgroundClasses[background],
      className,
    )}
  >
    <div className="container">
      <div className="flex flex-col gap-5 text-center lg:flex-row lg:text-left">
        <hgroup
          className={cn(
            'flex w-full flex-col items-center justify-center lg:w-5/12 lg:items-start',
            {
              'lg:order-1': imagePosition === 'left',
            },
          )}
        >
          <h3 className="mb-3">{title}</h3>
          {body && <p className="text-balance text-lg">{body}</p>}
          {link?.href ? (
            <LinkButton className="mt-5 justify-start" link={link} showIcon />
          ) : null}
        </hgroup>
        {image ? (
          <img
            className={cn('w-full lg:w-7/12', {
              'lg:-order-1': imagePosition === 'left',
            })}
            loading="lazy"
            src={image.src}
            alt={image.alt}
          />
        ) : null}
      </div>
    </div>
  </section>
)
