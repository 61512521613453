import { Badge } from '@datasnipper/ui/badge'
import { cn } from '@datasnipper/utils/cn'

import { backgroundClasses } from '#app/constants/shared'
import { BackgroundClass, BadgeAttributes } from '#types/shared'

export type TitleSectionProps = {
  title: string
  subtitle?: string
  reverseOrder?: boolean
  background?: BackgroundClass
  badge?: BadgeAttributes
  layout?: 'column' | 'row'
  textAlignment?: 'center' | 'left'
}

export const TitleSection = ({
  badge,
  subtitle,
  title,
  background = 'white',
  layout = 'column',
  textAlignment = 'center',
  reverseOrder = false,
}: TitleSectionProps) => (
  <article className={cn('flex justify-center', backgroundClasses[background])}>
    <Title
      badge={badge}
      subtitle={subtitle}
      title={title}
      layout={layout}
      textAlignment={textAlignment}
      reverseOrder={reverseOrder}
    />
  </article>
)

export const Title = ({
  badge,
  subtitle,
  title,
  layout = 'column',
  textAlignment = 'center',
  reverseOrder = false,
}: Omit<TitleSectionProps, 'background'>) => (
  <hgroup
    className={cn('container', {
      'flex w-full flex-col items-center justify-center md:flex-row md:items-start md:justify-between':
        layout === 'row',
      'text-center': textAlignment === 'center',
    })}
  >
    <h3
      className={cn('flex flex-col items-center', {
        'md:w-3/12': layout === 'row',
        'order-1': reverseOrder,
      })}
    >
      {badge?.text ? (
        <Badge variant={badge.variant}>
          <span className="p-1">{badge.text}</span>
        </Badge>
      ) : null}
      {title}
    </h3>
    <p
      className={cn('mt-5', {
        'md:mt-0 md:w-7/12': layout === 'row',
      })}
    >
      {subtitle}
    </p>
  </hgroup>
)
