import {
  StatsDashboard,
  StatsDashboardProps,
} from '#app/components/stats-dashboard/stats-dashboard'
import { StatsDashboard as StatsDashboardEntry } from '#app/graphql/@generated/api'
import { GradientType } from '#types/shared'

const mapEntryToProps = (entry: StatsDashboardEntry): StatsDashboardProps => {
  const isGradientType = (value: unknown): value is GradientType =>
    (value as GradientType) !== undefined

  return {
    title: entry.title || '',
    subtitle: entry.subtitle || '',
    stats: (entry.stats || []).map(stat => ({
      metric: stat?.metric || '',
      description: stat?.description || '',
      color: isGradientType(stat?.color) ? stat?.color : 'core',
    })),
  }
}

export const CsStatsDashboard = ({ entry }: { entry: StatsDashboardEntry }) => (
  <StatsDashboard {...mapEntryToProps(entry)} />
)
