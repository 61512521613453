import {
  Dialog,
  DialogContent,
  DialogPortal,
  DialogOverlay,
} from '@datasnipper/ui/dialog'
import { useState } from 'react'

type Props = {
  vimeoId: string
  open: boolean
  onOpenChange: (open: boolean) => void
}

export function PlayVideo({ vimeoId, open, onOpenChange }: Props) {
  const [container, setContainer] = useState<HTMLDivElement | null>(null)

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogPortal container={container}>
        <DialogOverlay className="fixed inset-0" />
        <DialogContent className="flex aspect-video max-w-screen-lg border-none bg-transparent">
          <iframe
            allow="autoplay; fullscreen; picture-in-picture;"
            title="Introduction to DataSnipper"
            src={`https://player.vimeo.com/video/${vimeoId}`}
            className="absolute left-0 top-0 aspect-video size-full p-5 lg:m-0"
          />
        </DialogContent>
      </DialogPortal>

      <div
        ref={
          setContainer as React.Dispatch<
            React.SetStateAction<HTMLDivElement | null>
          >
        }
      />
    </Dialog>
  )
}
