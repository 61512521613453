import { Review } from '@datasnipper/ui/review'

import { ReviewType } from '#types/shared'

export type ReviewsSectionProps = {
  title: string
  subtitle: string
  reviews: ReviewType[]
}

export const ReviewsSection = ({
  title,
  subtitle,
  reviews,
}: ReviewsSectionProps) => {
  return (
    <article className="flex text-center">
      <div className="container">
        <h3 className="mb-4">{title}</h3>
        <p>{subtitle}</p>
        <section className="flex justify-center gap-5 py-16">
          {reviews.map((review, index) => (
            <Review
              className="px-10 italic"
              text={review.text}
              key={index}
              imgSrc={review.src}
              imgAlt={review.alt}
              score={review.score}
              size={'md'}
            />
          ))}
        </section>
      </div>
    </article>
  )
}
