import {
  TitleSection,
  TitleSectionProps,
} from '#app/components/title-section/title-section'
import { TitleSection as TitleSectionEntry } from '#app/graphql/@generated/api'
import { getValidatedProperty, isValidProperty } from '#app/utils/shared'
import { BadgeAttributes } from '#types/shared'

const mapEntryToProps = (entry: TitleSectionEntry): TitleSectionProps => {
  const {
    background,
    badge,
    layout,
    reverse_order,
    subtitle,
    text_alignment,
    title,
  } = entry

  return {
    title: title ?? '',
    subtitle: subtitle ?? '',
    reverseOrder: reverse_order ?? false,
    layout: getValidatedProperty(
      layout,
      'column',
      isValidProperty<TitleSectionProps['layout']>,
    ),
    textAlignment: getValidatedProperty(
      text_alignment,
      'center',
      isValidProperty<TitleSectionProps['textAlignment']>,
    ),
    background: getValidatedProperty(
      background,
      'white',
      isValidProperty<TitleSectionProps['background']>,
    ),
    badge: {
      text: badge?.text || '',
      variant: getValidatedProperty(
        badge?.variant,
        'primary',
        isValidProperty<BadgeAttributes['variant']>,
      ),
    },
  }
}

export const CsTitleSection = ({ entry }: { entry: TitleSectionEntry }) => (
  <TitleSection {...mapEntryToProps(entry)} />
)
