import { cn } from '@datasnipper/utils/cn'

import { Banner, BannerProps } from '../banner/banner'

export type FeatureBannerProps = {
  title: string
  subtitle: string
  bannerItems?: BannerProps[]
}

export const FeatureBanner = ({
  title,
  subtitle,
  bannerItems = [],
}: FeatureBannerProps) => (
  <article
    className={cn('flex justify-center', { 'py-0': !title && !subtitle })}
  >
    <div className="container">
      {(title || subtitle) && (
        <hgroup className="mb-24 text-center">
          <h3 className="mb-2">{title}</h3>
          <p>{subtitle}</p>
        </hgroup>
      )}
      {bannerItems.map((bannerItem, index) => (
        <Banner
          key={index}
          className={cn({
            'pb-0': index === 0,
            'pt-0': index === bannerItems.length - 1,
          })}
          {...bannerItem}
        />
      ))}
    </div>
  </article>
)
