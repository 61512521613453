import { Icon } from '@datasnipper/icons'
import { cn } from '@datasnipper/utils/cn'

interface Props {
  imgSrc: string
  imgAlt: string
  text?: string
  score: number // The score out of 5 (e.g. 4.9 / 5)
  size?: 'sm' | 'md' | 'lg'
  className?: string
}

export function Review({
  imgSrc,
  imgAlt,
  text,
  score,
  size = 'lg',
  className,
}: Props) {
  return (
    <div
      className={cn(
        'inline-flex flex-col items-center',
        size === 'sm' ? 'gap-y-2' : 'gap-y-4',
        className,
      )}
    >
      <img
        className={cn('h-auto', size === 'lg' ? 'w-[120px]' : 'w-[94px]')}
        src={imgSrc}
        alt={imgAlt}
      />
      {text ? (
        <p
          className={cn(
            'block max-w-[240px] text-balance text-center text-base',
            size === 'sm' && 'text-sm',
            size === 'md' && 'text-base',
            size === 'lg' && 'max-w-[300px] text-lg leading-8',
          )}
        >
          {text}
        </p>
      ) : null}
      <div className="text-center">
        <div
          className={cn(
            'flex',
            size === 'sm' && 'mb-1',
            size === 'md' && 'mb-2',
            size === 'lg' && 'mb-3',
          )}
        >
          {Array.from({ length: 5 }, (_, i) => (
            <Icon
              key={i}
              size={size === 'sm' ? 'sm' : size === 'md' ? 'lg' : '2xl'}
              name={i + 1 === 5 && score < 4.8 ? 'star-half' : 'star-full'}
            />
          ))}
        </div>
        <span
          className={cn(
            'text-base font-semibold text-[#6E819E]',
            size === 'sm' && 'text-sm',
          )}
        >
          {score} / 5
        </span>
      </div>
    </div>
  )
}
