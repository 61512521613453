import type { Config } from 'tailwindcss'
import animatePlugin from 'tailwindcss-animate'
import radixPlugin from 'tailwindcss-radix'

import { extendedTheme } from './theme'

export { extendedTheme }

const config: Omit<Config, 'content'> = {
  theme: {
    extend: extendedTheme,
  },
  plugins: [animatePlugin, radixPlugin],
}

export default config
