import { useEffect, useState, useCallback } from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'

import tailwindConfig from '../../../tailwind.config'

const DEFAULT_SCREEN = 'lg'
const DEFAULT_SCREEN_SIZE = 1280

const { screens } = resolveConfig(tailwindConfig).theme

type ValidScreens = keyof typeof screens

type Screens = Record<ValidScreens, string>

export const useCurrentScreenSize = () => {
  const [closestScreenSize, setClosestScreenSize] =
    useState(DEFAULT_SCREEN_SIZE)
  const [closestScreen, setClosestScreen] = useState(DEFAULT_SCREEN)

  const validScreenSizes = Object.values(screens)
    .map(screen => Number(screen.replace('px', '')))
    .sort((a, b) => a - b)

  const findClosestScreen = useCallback(
    (width: number) => {
      const closestSize = validScreenSizes.reduce((prev, curr) =>
        Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev,
      )

      setClosestScreen(
        Object.keys(screens).find(
          key => screens[key as keyof Screens] === `${closestSize}px`,
        ) ?? DEFAULT_SCREEN,
      )
      setClosestScreenSize(closestSize)
    },
    [validScreenSizes],
  )

  useEffect(() => {
    const handleResize = () => {
      findClosestScreen(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [findClosestScreen])

  useEffect(() => {
    findClosestScreen(window.innerWidth)
  }, [findClosestScreen])

  return { closestScreenSize, closestScreen, isMobile: closestScreen === 'sm' }
}
