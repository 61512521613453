import { IconName } from '@datasnipper/icons'
import { BadgeProps } from '@datasnipper/ui/badge'

import { mapAsset } from './mappers.ts'

import { CardBanner } from '#app/components/card-banner/card-banner.tsx'
import { type CardBanner as CardBannerEntry } from '#app/graphql/@generated/api.ts'

export function CsCardBanner({ entry }: { entry: CardBannerEntry }) {
  return (
    <CardBanner
      background={
        (entry.background_color as 'gray' | 'white' | 'decorated') ?? 'gray'
      }
      title={entry.super_title?.title ?? ''}
      badge={
        entry.super_title?.badge?.text
          ? {
              text: entry.super_title.badge.text ?? '',
              variant: (entry.super_title.badge.variant ??
                '') as BadgeProps['variant'],
            }
          : undefined
      }
      image={mapAsset(entry.imageConnection?.edges?.[0])}
      imagePosition="right"
      cardItems={entry.card_items?.filter(Boolean).map(item => ({
        title: item.title ?? '',
        content: item.content ?? '',
        iconName: (item.icon_name ?? '') as IconName,
      }))}
    />
  )
}
