import { cn } from '@datasnipper/utils/cn'

import { backgroundClasses } from '#app/constants/shared'
import { BackgroundClass, ImageAttributes } from '#types/shared'

export type CompanyLogosSectionProps = {
  items: ImageAttributes[]
  background?: BackgroundClass
}

export function CompanyLogosSection({
  background = 'gray',
  items,
}: CompanyLogosSectionProps) {
  return (
    <article
      className={cn(
        'flex items-center justify-center py-16',
        backgroundClasses[background],
      )}
    >
      <section
        className={cn(
          'container',
          'flex flex-wrap items-center justify-center gap-14',
          'py-0 opacity-70 brightness-200',
        )}
        style={{
          maskImage:
            'linear-gradient(to right, transparent, black 20%, black 80%, transparent 100%)',
        }}
      >
        {items.map((logo, index) => (
          <img
            loading="lazy"
            className="m-0 max-h-10 w-fit"
            key={index}
            src={logo.src}
            alt={logo.alt}
          />
        ))}
      </section>
    </article>
  )
}
