import {
  ServiceOfferings,
  ServiceOfferingsProps,
} from '#app/components/service-offerings/service-offerings'
import { ServiceOfferings as ServiceOfferingsEntry } from '#app/graphql/@generated/api'

// Define the transformation function outside of the component to avoid re-creating it on every render
const transformEntryToProps = (
  entry: ServiceOfferingsEntry,
): ServiceOfferingsProps => {
  const { title, subtitle, services } = entry

  return {
    title: title ?? '',
    subtitle: subtitle ?? '',
    services: services?.map(service => ({
      title: service?.title ?? '',
      description: service?.description ?? '',
      features: service?.features ?? [],
    })) as ServiceOfferingsProps['services'],
  }
}

export function CsServiceOfferings({
  entry,
}: {
  entry: ServiceOfferingsEntry
}) {
  const props = transformEntryToProps(entry)

  return entry ? <ServiceOfferings {...props} /> : null
}
