import { Card, CardContent } from '@datasnipper/ui/card'
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@datasnipper/ui/carousel'
import { Review } from '@datasnipper/ui/review'
import { ScrollArea } from '@datasnipper/ui/scroll-area'
import { cn } from '@datasnipper/utils/cn'
import { useEffect, useState } from 'react'

import { LinkButton } from '../link-button/link-button'

import { getImageProps } from '#app/utils/images.js'
import type { ImageAttributes, LinkType, ReviewType } from '#types/shared.ts'

type ReviewCardProps = {
  content: string
  logo: ImageAttributes
  image: ImageAttributes
  link: LinkType
}

type Props = {
  title: string
  subtitle: string
  backgroundImage: ImageAttributes
  reviewCards: ReviewCardProps[]
  reviews: ReviewType[]
}

export function CarouselBanner({
  title,
  subtitle,
  backgroundImage,
  reviewCards,
  reviews,
}: Props) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  const [api, setApi] = useState<CarouselApi>()

  // We need this side-effect to update the current index when the user changes the active slide,
  // either via clicking the buttons or dragging the slides manually
  useEffect(() => {
    if (!api) return

    setActiveSlideIndex(api.selectedScrollSnap())

    api.on('select', () => setActiveSlideIndex(api.selectedScrollSnap()))
  }, [api])

  return (
    <section
      style={{ backgroundImage: `url(${backgroundImage.src})` }}
      className={cn(
        `flex flex-col items-center justify-center space-y-8 px-4 text-center`,
        `overflow-hidden bg-cover bg-center`,
      )}
    >
      <div className="px-4 md:px-8">
        <h2 className="mx-20 px-4">{title}</h2>

        <p className="mx-16 px-4 pb-12 pt-5 text-lg text-body">{subtitle}</p>

        <Carousel setApi={setApi}>
          <CarouselContent className="max-w-md px-2 text-left md:max-w-screen-md md:px-4 lg:max-w-screen-lg lg:px-8">
            {reviewCards.map((card, index) => {
              const { image, logo, content, link } = card

              return (
                <CarouselItem
                  className="flex items-center justify-center pl-4 md:px-8"
                  key={index}
                >
                  <Card className="max-w-80 rounded-2xl sm:max-w-96 md:max-w-full">
                    <CardContent className="flex h-fit flex-col items-center gap-10 p-5 md:flex-row">
                      <figure className="h-72 min-w-64 overflow-hidden rounded-2xl align-middle md:max-w-80">
                        <img
                          loading="lazy"
                          {...getImageProps(image.src, {
                            widths: [479, 800, 991],
                            sizes: [
                              '(max-width: 479px) 85vw',
                              '(max-width: 991px) 83vw',
                              '800px',
                            ],
                          })}
                          alt={image.alt}
                          className="inline-block size-full rounded-2xl object-cover align-middle md:max-w-80"
                        />
                      </figure>
                      <div className="flex h-full flex-col items-start justify-center gap-4 pl-0 text-lg">
                        <ScrollArea className="h-40 text-ellipsis">
                          <div
                            className="text-base md:text-xl"
                            dangerouslySetInnerHTML={{ __html: content }}
                          />
                        </ScrollArea>

                        <img
                          loading="lazy"
                          className="h-10 w-24"
                          src={logo.src}
                          alt={logo.alt}
                        />
                        <LinkButton
                          className="py-0 font-semibold"
                          link={link}
                          openInNewTab
                        />
                      </div>
                    </CardContent>
                  </Card>
                </CarouselItem>
              )
            })}
          </CarouselContent>
          <div className="flex items-start justify-center gap-5 overflow-hidden pt-12">
            {(() => {
              const variant = 'outline'
              const className = `static h-12 w-12 -skew-x-12 rounded-sm bg-action-600 disabled:opacity-0 text-primary-foreground -left-12 top-1/2 -translate-y-1/2 transition duration-300 ease-in-out hover:bg-action-600/75 hover:text-primary-foreground size-10`

              return (
                <>
                  <CarouselPrevious variant={variant} className={className} />
                  <div className="flex space-x-2">
                    {reviewCards.map((_, i) => (
                      <button
                        key={i}
                        onClick={() => api?.scrollTo(i)}
                        className={cn(
                          className,
                          'size-4',
                          i === activeSlideIndex
                            ? 'w-8 bg-action-600'
                            : 'border border-border bg-background',
                        )}
                      >
                        <span className="sr-only">Go to slide {i + 1}</span>
                      </button>
                    ))}
                  </div>
                  <CarouselNext variant={variant} className={className} />
                </>
              )
            })()}
          </div>
        </Carousel>

        <div className="flex flex-col items-center gap-12 pt-12 md:block">
          {reviews?.map((review, index) => (
            <Review
              key={index}
              imgSrc={review.src}
              imgAlt={review.alt}
              score={review.score}
              text={review.text}
              size="md"
            />
          ))}
        </div>
      </div>
    </section>
  )
}
