import { Icon } from '@datasnipper/icons'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@datasnipper/ui/tabs'
import { cn } from '@datasnipper/utils/cn'
import { motion } from 'framer-motion'
import { ReactNode, useState } from 'react'

import { LinkButton } from '../link-button/link-button'

import { LinkType } from '#types/shared.js'

export type TabLayoutProps = {
  items: {
    title: string
    subtitle: string
    slot: ReactNode
    link: LinkType
  }[]
}

export function TabLayout({ items }: TabLayoutProps) {
  const [activeTab, setActiveTab] = useState('0')

  if (!items || items.length === 0) {
    return null
  }

  return (
    <div className="mx-auto w-full max-w-md">
      <Tabs
        defaultValue={'0'}
        value={activeTab}
        onValueChange={setActiveTab}
        className="flex flex-col"
      >
        <TabsList className="my-2 mt-5 flex-col bg-background px-5 sm:my-0 sm:flex-row">
          {items.map((item, index) => {
            return (
              <TabsTrigger
                className={cn(
                  `${index === Number(activeTab) ? 'underline decoration-highlight' : 'text-neutral-600/75'}`,
                  `text-base font-semibold leading-6 tracking-tight`,
                  `px-1 underline-offset-8 md:px-3`,
                )}
                key={index}
                value={`${index}`}
              >
                {item.title}
              </TabsTrigger>
            )
          })}
        </TabsList>
        {items.map((item, index) => {
          const isActive = activeTab === `${index}`

          return (
            <motion.div
              className="translate-y-10 sm:translate-y-5"
              animate={{ opacity: isActive ? 100 : 0 }}
              transition={{ ease: 'easeIn', duration: 0.35 }}
              key={index}
            >
              <TabsContent className="mt-0" value={`${index}`}>
                <div className="flex flex-col items-center justify-center">
                  <p className=" text-wrap px-2 pb-8 text-center text-base md:text-nowrap md:text-left">
                    {item.subtitle}
                  </p>
                  <div className="flex min-h-[20vh] w-full max-w-full items-center justify-center object-cover sm:max-w-screen-sm md:min-h-[30vh] md:max-w-screen-md lg:min-h-[47vh] lg:max-w-screen-lg">
                    {item.slot}
                  </div>
                  <LinkButton
                    className="mt-5"
                    link={{
                      children: (
                        <div className="flex items-center gap-x-1 text-lg font-semibold text-action-600">
                          {item.link.children}
                          <Icon name="arrow-right" size="md" />
                        </div>
                      ),
                      href: item.link.href,
                    }}
                  />
                </div>
              </TabsContent>
            </motion.div>
          )
        })}
      </Tabs>
    </div>
  )
}
