import { SuperTitle } from '../super-title/super-title'
import { TabLayout, type TabLayoutProps } from '../tab-layout/tab-layout'

import { BadgeAttributes, LinkType } from '#types/shared'

type Props = {
  title: string
  subtitle?: string
  badge?: BadgeAttributes
  tagline?: string
  link?: LinkType
  items: TabLayoutProps['items']
}

export function TabLayoutSection({
  title,
  subtitle,
  badge,
  tagline,
  items,
}: Props) {
  return (
    <section className="flex justify-center">
      <div className="container">
        <div className="flex flex-col items-center justify-center">
          <SuperTitle
            className="max-w-lg text-balance text-lg font-medium"
            title={title}
            badge={badge}
          />
          <p className="max-w-lg text-balance text-center text-lg text-body md:max-w-xl">
            {subtitle}
          </p>
          <h3 className="mb-6 mt-12 text-center text-2xl font-medium tracking-tight">
            {tagline}
          </h3>
          <TabLayout items={items} />
        </div>
      </div>
    </section>
  )
}
