import { mapAsset } from './mappers.ts'

import { BannerProps } from '#app/components/banner/banner.tsx'
import {
  FeatureBanner,
  FeatureBannerProps,
} from '#app/components/feature-banner/feature-banner.tsx'
import { type FeatureBanner as FeatureBannerEntry } from '#app/graphql/@generated/api.ts'
import { getValidatedProperty, isValidProperty } from '#app/utils/shared.ts'
import { BackgroundClass } from '#types/shared.ts'

const mapEntryToProps = (entry: FeatureBannerEntry): FeatureBannerProps => {
  const bannerItems = entry.banner_items?.map(
    (item): BannerProps => ({
      image: mapAsset(item?.imageConnection?.edges?.[0]),
      title: item?.title ?? '',
      body: item?.body ?? '',
      link: {
        children: item?.link?.title ?? '',
        href: item?.link?.href ?? '',
      },
      imagePosition: getValidatedProperty(
        item?.image_position,
        'left',
        isValidProperty<BannerProps['imagePosition']>,
      ),
      background: getValidatedProperty(
        item?.background,
        'white',
        isValidProperty<BackgroundClass>,
      ),
    }),
  )

  return {
    title: entry.title ?? '',
    subtitle: entry.subtitle ?? '',
    bannerItems,
  }
}

export const CsFeatureBanner = ({ entry }: { entry: FeatureBannerEntry }) => (
  <FeatureBanner {...mapEntryToProps(entry)} />
)
