export function getDomainUrl(request: Request) {
  const host =
    request.headers.get('X-Forwarded-Host') ??
    request.headers.get('host') ??
    new URL(request.url).host
  const protocol = host.includes('localhost') ? 'http' : 'https'
  return `${protocol}://${host}`
}

export function removeTrailingSlash(s: string) {
  return s.endsWith('/') ? s.slice(0, -1) : s
}

export function getUrl(requestInfo?: { origin: string; path: string }) {
  return removeTrailingSlash(
    `${requestInfo?.origin ?? 'https://saltagency.io'}${
      requestInfo?.path ?? ''
    }`,
  )
}

/**
 * Checks if `value` is an empty object, collection, map, or set.
 *
 * Objects are considered empty if they have no own enumerable string keyed properties.
 * Array-like values such as `arguments`, arrays, buffers, strings, or collections are considered empty if they have a `length` of `0`.
 * Similarly, maps and sets are considered empty if they have a `size` of `0`.
 *
 * @param {unknown} value The value to check.
 * @returns {boolean} Returns `true` if `value` is empty, else `false`.
 */
export function isEmpty(value: unknown): boolean {
  if (value == null) {
    return true
  }
  if (
    Array.isArray(value) ||
    typeof value === 'string' ||
    typeof value === 'function'
  ) {
    return !value.length
  }
  if (typeof value === 'object') {
    if (value instanceof Map || value instanceof Set) {
      return !value.size
    }
    return Object.keys(value as object).length === 0
  }
  return false
}
