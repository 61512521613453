import { cn } from '@datasnipper/utils/cn'

const sizeClassName = {
  sm: 'w-6 h-6',
  md: 'w-9 h-9',
  lg: 'w-12 h-12',
} as const

export function Avatar({
  src,
  alt,
  size = 'md',
  rounded = true,
  shadow = false,
  className,
}: {
  src: string
  alt: string
  size?: keyof typeof sizeClassName
  rounded?: boolean
  shadow?: boolean
  className?: string
}) {
  return (
    <div
      className={cn(
        'overflow-hidden',
        sizeClassName[size],
        rounded ? 'rounded-full' : 'rounded-lg',
        shadow && 'shadow-xl',
        className,
      )}
    >
      <img src={src} alt={alt} />
    </div>
  )
}
