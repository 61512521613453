import { BadgeProps } from '@datasnipper/ui/badge'

import { mapAsset, mapLink } from './mappers'

import { Verticals } from '#app/components/verticals/verticals.tsx'
import { type Verticals as VerticalsEntry } from '#app/graphql/@generated/api.ts'

export function CsVerticals({ entry }: { entry: VerticalsEntry }) {
  return (
    <Verticals
      title={entry.super_title?.title ?? ''}
      subtitle={entry.super_title?.subtitle ?? ''}
      iconVariant={entry.icon_variant ?? false}
      items={(entry.items ?? []).filter(Boolean).map(item => {
        const icon = mapAsset(item.iconConnection?.edges?.[0])

        return {
          title: item.title ?? '',
          subtitle: item.subtitle ?? '',
          link: mapLink(item.link),
          icon,
        }
      })}
      badge={
        entry.super_title?.badge?.text
          ? {
              text: entry.super_title.badge.text ?? '',
              variant: (entry.super_title.badge.variant ??
                '') as BadgeProps['variant'],
            }
          : undefined
      }
    />
  )
}
