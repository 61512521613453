import { cn } from '@datasnipper/utils/cn'

import { ImageAttributes } from '#types/shared'

type Props = {
  items: ImageAttributes[]
  invert?: boolean
}

export function CompanyLogos({ items, invert = true }: Props) {
  return (
    <div
      className={cn(
        'flex items-center justify-center',
        'max-w-3xl flex-wrap gap-4 py-2 sm:gap-8',
        'opacity-70 brightness-200',
      )}
    >
      {items.map((logo, index) => (
        <img
          loading="lazy"
          className={cn('m-0 max-h-10 w-fit', invert ? 'invert' : '')}
          key={index}
          src={logo.src}
          alt={logo.alt}
        />
      ))}
    </div>
  )
}
