import { Icon } from '@datasnipper/icons'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@datasnipper/ui/table'
import { cn } from '@datasnipper/utils/cn'
import { Fragment } from 'react'

import { Tip } from '../tooltip/tooltip'

type Feature = {
  name: string
  basic: boolean | string
  professional: boolean | string
  enterprise: boolean | string
  info?: string
}

export type CategoryFeatures = {
  name: string
  features: Feature[]
}

export type FeatureComparisonTableProps = {
  title: string
  tableCaption: string
  featureNames: string[]
  features: CategoryFeatures[]
}

export function FeatureComparisonTable({
  title,
  features,
  featureNames,
  tableCaption,
}: FeatureComparisonTableProps) {
  return (
    <section className="flex justify-center p-4 sm:p-6 lg:p-8">
      <div className="container mx-auto">
        <h2 className="mb-4 text-center text-lg sm:text-xl lg:text-2xl">
          {title}
        </h2>

        <Table className="w-full caption-top overflow-x-auto">
          <TableCaption className="mb-4 font-sans text-base sm:text-lg lg:text-xl">
            {tableCaption}
          </TableCaption>
          <TableHeader>
            <TableRow>
              {featureNames.map(featureName => (
                <TableHead
                  className="w-auto font-display text-sm font-medium text-headline sm:text-base lg:text-lg"
                  key={featureName}
                >
                  {featureName}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {features.map((categoryFeature, categoryIndex) => (
              <Fragment key={categoryIndex}>
                <TableRow
                  className="border-none"
                  key={`${categoryIndex}-${categoryFeature}`}
                >
                  <TableCell className="py-3 text-base font-medium text-headline sm:py-6 sm:text-lg lg:text-xl">
                    {categoryFeature.name}
                  </TableCell>
                </TableRow>
                {categoryFeature.features.map((feature, featureIndex) => (
                  <TableRow
                    className={cn(
                      'border-none',
                      featureIndex % 2 === 0
                        ? 'bg-background'
                        : 'bg-background-alt/25',
                    )}
                    key={`${categoryIndex}-${featureIndex}`}
                  >
                    <TableCell className="flex items-center justify-start gap-3 text-xs sm:w-auto sm:text-sm lg:text-base">
                      {feature.name}
                      {feature.info ? (
                        <Tip
                          content={
                            <p className="w-full px-2 py-3 sm:w-72 sm:px-3 sm:py-5">
                              {feature.info}
                            </p>
                          }
                        >
                          <div className="flex items-center">
                            <Icon
                              aria-label={`${feature.name} info button`}
                              name="info"
                              className="size-4"
                            />
                          </div>
                        </Tip>
                      ) : null}
                    </TableCell>
                    <TableCell className="w-24 sm:w-32 lg:w-48">
                      {renderFeatureAvailability(feature.basic)}
                    </TableCell>
                    <TableCell className="w-24 sm:w-32 lg:w-48">
                      {renderFeatureAvailability(feature.professional)}
                    </TableCell>
                    <TableCell className="w-24 sm:w-32 lg:w-48">
                      {renderFeatureAvailability(feature.enterprise)}
                    </TableCell>
                  </TableRow>
                ))}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </div>
    </section>
  )
}

function renderFeatureAvailability(availability: boolean | string) {
  if (typeof availability === 'string') return availability

  return availability ? (
    <Icon name="check" className="size-4 text-core-200" />
  ) : (
    <span className="ml-1">-</span>
  )
}
