import { BadgeProps } from '@datasnipper/ui/badge'
import { Card, CardContent, CardFooter } from '@datasnipper/ui/card'
import { Grid } from '@datasnipper/ui/grid'
import { cn } from '@datasnipper/utils/cn'
import { Link } from '@remix-run/react'
import clamp from 'lodash/clamp'

import { LinkButton } from '../link-button/link-button'
import { SuperTitle } from '../super-title/super-title'

import { gridColumns } from '#app/constants/shared'
import { useCurrentScreenSize } from '#app/hooks/useCurrentScreenSize/useCurrentScreenSize'
import { ImageAttributes, LinkType } from '#types/shared'

type Item = {
  title: string
  subtitle: string
  link: LinkType
  icon?: ImageAttributes
}

type Props = {
  title?: string
  subtitle?: string
  badge?: {
    text: string
    variant: BadgeProps['variant']
  }
  items: Item[]
  iconVariant?: boolean
}

export function Verticals({
  items,
  title,
  subtitle,
  badge,
  iconVariant,
}: Props) {
  const { closestScreen } = useCurrentScreenSize()

  const gridCols = gridColumns[clamp(items.length - 1, 0, 11)]

  return (
    <section className="flex justify-center bg-gradient-to-b from-neutral-25 to-background">
      <div className="container">
        {title && (
          <div className="mx-auto text-center">
            <SuperTitle
              title={title}
              subtitle={subtitle}
              badge={badge}
              className="mb-20 text-balance font-medium"
            />
          </div>
        )}

        <Grid
          className={`${iconVariant ? 'grid-cols-2 sm:grid-cols-3 lg:grid-cols-6' : cn('grid-cols-1', { [`${gridCols}`]: !['sm', 'md'].includes(closestScreen) })} gap-5`}
        >
          {items.map((item, index) => (
            <Card
              key={index}
              className={`text-center ${iconVariant ? 'bg-neutral-25 p-6 hover:cursor-pointer hover:bg-neutral-50' : 'flex flex-col items-center'}`}
            >
              {iconVariant ? (
                <Link
                  reloadDocument
                  to={item.link.href}
                  className="flex flex-col items-center gap-6"
                >
                  <img
                    loading="lazy"
                    className="size-24"
                    src={item.icon?.src}
                    alt={item.icon?.alt}
                  />
                  <p className="text-lg font-semibold">{item.title}</p>
                </Link>
              ) : (
                <>
                  <CardContent className="min-w-44 max-w-48 px-8 py-5">
                    <img
                      loading="lazy"
                      className="size-24"
                      src={item.icon?.src}
                      alt={item.icon?.alt}
                    />
                    <h4 className="text-xl font-medium">{item.title}</h4>
                    <p className="text-muted">{item.subtitle}</p>
                  </CardContent>
                  <CardFooter>
                    <LinkButton link={item.link} showIcon />
                  </CardFooter>
                </>
              )}
            </Card>
          ))}
        </Grid>
      </div>
    </section>
  )
}
