import type {
  Link,
  SuperHeroBannerReviews,
  SysAssetEdge,
} from '#app/graphql/@generated/api.ts'
import type { ImageAttributes, LinkType, ReviewType } from '#types/shared.ts'

export function mapAsset(
  edge: SysAssetEdge | null | undefined,
): ImageAttributes {
  if (!edge?.node?.url) {
    console.warn('Found Asset entry without URL', edge?.node)
  }
  return {
    alt: edge?.node?.description ?? '',
    src: edge?.node?.url ?? '',
  }
}

/**
 * @deprecated Replace with mapLink in apps/website/app/contentstack/hero-header.tsx
 */
export function mapLink(entry: Link | null | undefined): LinkType {
  return {
    children: entry?.title ?? '',
    href: entry?.href ?? '',
  }
}

// TODO: we should have a global type for Reviews in CS
export function mapReview(entry: SuperHeroBannerReviews | null): ReviewType {
  return {
    ...mapAsset(entry?.logoConnection?.edges?.[0]),
    score: entry?.score ?? 0,
  }
}
