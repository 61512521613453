export const backgroundClasses = {
  white: 'bg-background',
  gray: 'bg-neutral-25',
  darkGrey: 'bg-neutral-50',
  core: 'bg-core-25',
  action: 'bg-action-25',
  highlight: 'bg-highlight-25',
}

export const gridColumns = [
  'grid-cols-1',
  'grid-cols-2',
  'grid-cols-3',
  'grid-cols-4',
  'grid-cols-5',
  'grid-cols-6',
  'grid-cols-7',
  'grid-cols-8',
  'grid-cols-9',
  'grid-cols-10',
  'grid-cols-11',
  'grid-cols-12',
]
