import { Icon } from '@datasnipper/icons'

import { mapAsset } from './mappers.ts'

import { CarouselBanner } from '#app/components/carousel-banner/carousel-banner.tsx'
import { type CarouselBanner as CarouselBannerEntry } from '#app/graphql/@generated/api.ts'

export function CsCarouselBanner({ entry }: { entry: CarouselBannerEntry }) {
  return (
    <CarouselBanner
      title={entry.main_title ?? ''}
      subtitle={entry.subtitle ?? ''}
      backgroundImage={mapAsset(entry.background_imageConnection?.edges?.[0])}
      reviews={(entry.reviews ?? []).filter(Boolean).map(review => ({
        text: review.text ?? '',
        score: review.score ?? 5,
        ...mapAsset(review.company_logoConnection?.edges?.[0]),
      }))}
      reviewCards={(entry.review_cards ?? [])
        .filter(Boolean)
        .map(reviewCard => ({
          content: reviewCard.content ?? '',
          logo: mapAsset(reviewCard.logoConnection?.edges?.[0]),
          image: mapAsset(reviewCard.imageConnection?.edges?.[0]),
          link: {
            href: reviewCard.link?.href ?? '',
            children: (
              <div className="flex gap-1">
                {reviewCard.link?.title ?? ''}
                <Icon name="arrow-right" size="md" />
              </div>
            ),
          },
        }))}
    />
  )
}
