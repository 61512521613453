import { Badge } from '@datasnipper/ui/badge'
import { Button } from '@datasnipper/ui/button'
import { Card, CardContent, CardFooter, CardHeader } from '@datasnipper/ui/card'
import { Separator } from '@datasnipper/ui/separator'
import { useState } from 'react'

import { LinkButton } from '../link-button/link-button'

import { ImageAttributes, LinkAttributes } from '#types/shared'

interface CustomerStoryCard {
  name: string
  description: string
  vertical: string
  logo: ImageAttributes
  link: LinkAttributes
}

export type CustomerStoriesProps = {
  title: string
  subtitle: string
  customerStoryCards: CustomerStoryCard[]
}

const INITIAL_DISPLAY_COUNT = 6

export const CustomerStories = ({
  title,
  subtitle,
  customerStoryCards,
}: CustomerStoriesProps) => {
  const [displayCount, setDisplayCount] = useState(INITIAL_DISPLAY_COUNT)

  const showMoreCustomers = () => {
    setDisplayCount(prevCount => prevCount + INITIAL_DISPLAY_COUNT)
  }

  return (
    <article className="flex items-center justify-center bg-neutral-50 text-center">
      <div className="container">
        <h3 className="mb-4">{title}</h3>
        <p className="mb-12">{subtitle}</p>
        <section className="grid grid-cols-1 gap-6 py-0 md:grid-cols-2 lg:grid-cols-3">
          {customerStoryCards
            .slice(0, displayCount)
            .map(({ name, logo, description, vertical, link }) => (
              <Card key={name} className="border-neutral-200">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <img src={logo.src} alt={logo.alt} className="h-10" />
                  <Badge
                    variant="neutral"
                    className="px-2 py-1 font-sans text-base font-semibold"
                  >
                    {vertical}
                  </Badge>
                </CardHeader>
                <CardContent className="pb-0">
                  <Separator className="mb-3 mt-2" />
                  <p className="text-left text-body">{description}</p>
                </CardContent>
                <CardFooter>
                  <LinkButton
                    openInNewTab
                    showIcon
                    link={{ href: link.title, children: link.title }}
                  />
                </CardFooter>
              </Card>
            ))}
        </section>
        {displayCount < customerStoryCards.length && (
          <Button
            variant="outline"
            className="mt-12 bg-neutral-100"
            onClick={showMoreCustomers}
          >
            Show More
          </Button>
        )}
      </div>
    </article>
  )
}
