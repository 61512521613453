import { BadgeProps } from '@datasnipper/ui/badge'

import { mapAsset } from './mappers.ts'

import { TabLayoutSection } from '#app/components/tab-layout-section/tab-layout-section.js'
import { TabLayout } from '#app/graphql/@generated/api.ts'
import { getImageProps } from '#app/utils/images.js'

export function CsTabLayoutSection({ entry }: { entry: TabLayout }) {
  return (
    <TabLayoutSection
      title={entry.super_title?.title ?? ''}
      badge={
        entry.super_title?.badge?.text
          ? {
              text: entry.super_title.badge.text ?? '',
              variant: (entry.super_title.badge.variant ??
                '') as BadgeProps['variant'],
            }
          : undefined
      }
      subtitle={entry.super_title?.subtitle ?? ''}
      tagline={entry.tagline ?? ''}
      items={(entry.tab_items ?? []).filter(Boolean).map(item => {
        const image = mapAsset(item.imageConnection?.edges?.[0])

        return {
          title: item.title ?? '',
          subtitle: item.subtitle ?? '',
          slot: (
            <>
              <img
                className="max-w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg"
                loading={'lazy'}
                alt={image.alt}
                {...getImageProps(image.src, {
                  widths: [500, 2000],
                  sizes: ['(max-width: 600px) 500px', '2000px'],
                })}
              />
            </>
          ),
          link: {
            href: item.link?.href ?? '#',
            children: item.link?.title ?? 'Explore platform',
          },
        }
      })}
    />
  )
}
