import { cn } from '@datasnipper/utils/cn'

export type HeroProps = {
  title: string
  subtitle: string
}

export const Hero = ({ title, subtitle }: HeroProps) => (
  <article
    className={cn(
      `bg-none sm:bg-[url(/images/pattern.svg)]`,
      `top-0 z-50 bg-neutral-800 bg-cover bg-no-repeat`,
      `flex flex-col items-center justify-between py-32 pb-0 md:py-56`,
    )}
  >
    <div className="container flex flex-col items-center justify-center gap-4 px-4 text-center">
      <h1 className="mt-12 max-w-[70%] text-pretty text-display text-primary-foreground md:mt-0 ">
        {title}
      </h1>
      <p className="mb-24 max-w-[60%] text-pretty text-lg text-neutral md:mb-0">
        {subtitle}
      </p>
    </div>
  </article>
)
