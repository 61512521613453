import { cn } from '@datasnipper/utils/cn'

export interface Item {
  number: string
  description: string
  variation: string
}

export type CoreNumbersProps = {
  items: Item[]
}

export const CoreNumbers = ({ items }: CoreNumbersProps) => (
  <article className="flex justify-center">
    <div
      className={cn(
        `container flex flex-col gap-5 md:flex-row md:justify-between lg:gap-20`,
        `overflow-hidden bg-gradient-to-b from-accent to-feedback bg-clip-text px-4  md:bg-gradient-to-r`,
      )}
    >
      {items.map((item, index) => (
        <div
          key={index}
          className={cn(
            `font-display font-bold`,
            `flex w-full flex-col items-center justify-center gap-5`,
            `py-5 md:py-0`,
          )}
        >
          <div className="flex items-baseline text-transparent">
            <span className="text-7xl md:text-9xl">{item.number}</span>
            <span className="text-6xl md:text-7xl">{item.variation}</span>
          </div>
          <p className="line-clamp-2 max-w-32 text-balance text-center font-sans text-lg font-semibold text-headline">
            {item.description}
          </p>
        </div>
      ))}
    </div>
  </article>
)
