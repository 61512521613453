import { Icon, IconName } from '@datasnipper/icons'
import { Card, CardContent, CardHeader } from '@datasnipper/ui/card'
import { cn } from '@datasnipper/utils/cn'

import { SuperTitle } from '../super-title/super-title'

import { getImageProps } from '#app/utils/images.ts'
import { BadgeAttributes, ImageAttributes, LinkType } from '#types/shared'

const bgClass = {
  white: 'bg-background',
  gray: 'bg-neutral-25',
  decorated: 'bg-primary',
}

const cardGradientClass = {
  white: 'to-background',
  gray: 'to-neutral-25',
  decorated: 'to-action-200/15',
}

const accentClass = {
  white: 'text-highlight-500',
  gray: 'text-primary',
  decorated: 'text-highlight-500',
}

type Props = {
  image: ImageAttributes
  title: string
  body?: string
  className?: string
  link?: LinkType
  badge?: BadgeAttributes
  imagePosition?: 'right' | 'left'
  accentColor?: keyof typeof accentClass
  background?: keyof typeof bgClass
  gradient?: keyof typeof cardGradientClass
  cardItems?: {
    title: string
    content: string
    iconName: IconName
  }[]
}

export function CardBanner({
  image,
  title,
  imagePosition = 'right',
  accentColor = 'decorated',
  gradient = 'decorated',
  background = 'gray',
  className,
  badge,
  cardItems,
}: Props) {
  return (
    <section
      className={cn(`flex justify-center`, bgClass[background], className)}
    >
      <div className="container">
        <SuperTitle
          title={title}
          className="mb-12 max-w-md font-medium"
          badge={badge}
        />
        <div className="flex w-full flex-col items-center justify-between gap-8 lg:flex-row">
          <div
            className={cn(
              'flex max-w-xl flex-col items-start justify-center gap-8',
              imagePosition === 'left' && 'order-1',
            )}
          >
            {cardItems?.map((item, index) => (
              <Card
                key={index}
                className={cn(
                  cardGradientClass[gradient],
                  `bg-gradient-to-br from-background shadow-lg`,
                  `max-w-xl md:p-4`,
                )}
              >
                <CardHeader>
                  <p className="flex items-center gap-2 text-lg font-semibold leading-7">
                    <Icon
                      className={accentClass[accentColor]}
                      name={item.iconName}
                      size="md"
                    />
                    {item.title}
                  </p>
                </CardHeader>
                <CardContent className="pt-0 text-lg text-body">
                  {item.content}
                </CardContent>
              </Card>
            ))}
          </div>
          <div
            className={cn(
              'col-span-full lg:col-span-7',
              imagePosition === 'left' && '-order-1',
            )}
          >
            <img
              loading="lazy"
              className="w-screen max-w-md px-2 sm:w-96 md:px-0 lg:w-full lg:max-w-xl xl:max-w-2xl"
              alt={image.alt}
              {...getImageProps(image.src, {
                widths: [500, 800, 1080, 1453],
                sizes: [
                  '(max-width: 479px) 100vw',
                  '(max-width: 991px) 95vw',
                  '(max-width: 1279px) 44vw',
                  '(max-width: 1439px) 528px',
                  '560px',
                ],
              })}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
