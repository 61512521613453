import {
  AddOnModuleSection,
  AddOnModuleCard,
} from '#app/components/add-on-module-card-section/add-on-module-card-section'
import {
  AddOnModulesCards,
  Maybe,
  type AddOnModules as AddOnModulesEntry,
} from '#app/graphql/@generated/api.ts'

export function CsAddOnModuleSection({ entry }: { entry: AddOnModulesEntry }) {
  const mapAddOnModulesCardsToProps = (
    addOnModulesCards: Maybe<AddOnModulesCards>[],
  ): AddOnModuleCard[] =>
    addOnModulesCards.map(card => ({
      cardTitle: card?.card_title || '',
      heading: card?.title || '',
      description: card?.description || '',
      features: (card?.features || []).map(feature => ({
        text: feature || '',
      })),
      link: {
        children: card?.link?.title || null,
        href: card?.link?.href || '',
      },
    }))

  return entry.cards ? (
    <AddOnModuleSection cards={mapAddOnModulesCardsToProps(entry.cards)} />
  ) : null
}
