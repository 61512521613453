import { Icon } from '@datasnipper/icons'
import { Badge } from '@datasnipper/ui/badge'
import { Button } from '@datasnipper/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@datasnipper/ui/card'
import { Grid } from '@datasnipper/ui/grid'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@datasnipper/ui/tooltip'
import { cn } from '@datasnipper/utils/cn'
import { Link } from '@remix-run/react'

import { LinkType } from '#types/shared.js'

export interface Tier {
  name: string
  audience: string
  description: string
  teamSize: { description: string; tooltip?: string }
  features: {
    title: string
    items: string[]
  }[]
  badge?: string
  link: LinkType
}

export type PricingTableProps = {
  tiers: Tier[]
}

export function PricingTable({ tiers }: PricingTableProps) {
  return (
    <section className="flex justify-center">
      <div className="container">
        <Grid className="mx-auto grid-cols-1 place-items-center gap-5	gap-x-4 px-4 py-8  md:grid-cols-3 md:place-items-start lg:gap-6">
          {tiers.map((tier, index) => (
            <Card
              key={index}
              className={cn(
                'w-full max-w-96 py-12 text-neutral-600',
                'z-10 flex flex-col justify-between',
              )}
            >
              <CardHeader className="min-h-72 justify-between overflow-hidden pt-0">
                <CardTitle className="flex items-center justify-between">
                  <h3>{tier.name}</h3>
                  {tier.badge && (
                    <Badge className="relative left-10 z-0" variant="highlight">
                      <span className="p-2 pr-4 font-medium uppercase tracking-wide text-neutral-100">
                        {tier.badge}
                      </span>
                    </Badge>
                  )}
                </CardTitle>
                <p className="pb-12 text-lg">{tier.audience}</p>
                <p className="flex items-start">{tier.description}</p>
                <p className="flex items-center justify-start gap-4 pt-5">
                  <span>{tier.teamSize.description}</span>
                  {tier.teamSize.tooltip ? (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <div className="flex items-center">
                            <Icon name="eye" className="size-4" />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent sideOffset={0}>
                          <p>{tier.teamSize.tooltip}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  ) : null}
                </p>
              </CardHeader>
              <CardContent className="flex grow flex-col">
                <ul className="mb-6 min-h-full space-y-2 pb-16 md:min-h-[500px]">
                  {tier.features.map((feature, featureIndex) => (
                    <li
                      key={featureIndex}
                      className="flex flex-col items-start"
                    >
                      <span className="pb-3 text-lg font-bold">
                        {feature.title}
                      </span>
                      <ul className="list-disc">
                        {feature.items.map((item, itemIndex) => (
                          <li
                            key={itemIndex}
                            className="mb-2 flex items-center text-sm"
                          >
                            <Icon
                              name="check"
                              className="mr-2 size-4 shrink-0"
                            />
                            <span>{item}</span>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter>
                <Link to={tier.link.href} className="w-full">
                  <Button
                    variant="outline"
                    className="w-full border-action-500 hover:bg-action-500 hover:text-primary-foreground"
                  >
                    {tier.link.children}
                  </Button>
                </Link>
              </CardFooter>
            </Card>
          ))}
        </Grid>
      </div>
    </section>
  )
}
