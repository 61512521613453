import { type Config } from 'tailwindcss'

/** The CSS variables used here are currently maintained in packages/ui/styles.css */
export const extendedTheme = {
  colors: {
    body: {
      DEFAULT: 'hsl(var(--neutral-600))',
      'low-contrast': 'hsl(var(--neutral-500))',
    },
    border: {
      DEFAULT: 'hsl(var(--border))',
    },
    input: {
      DEFAULT: 'hsl(var(--input))',
      invalid: 'hsl(var(--input-invalid))',
    },
    ring: {
      DEFAULT: 'hsla(var(--ring))',
      invalid: 'hsl(var(--destructive))',
    },
    background: {
      DEFAULT: 'hsl(var(--background))',
      alt: 'hsl(var(--background-alt))',
    },
    foreground: {
      DEFAULT: 'hsl(var(--foreground))',
      light: 'hsl(var(--foreground-light))',
    },
    primary: {
      DEFAULT: 'hsl(var(--primary))',
      foreground: 'hsl(var(--primary-foreground))',
      light: 'hsl(var(--primary-light))',
    },
    secondary: {
      DEFAULT: 'hsl(var(--secondary))',
      foreground: 'hsl(var(--secondary-foreground))',
    },
    destructive: {
      DEFAULT: 'hsl(var(--destructive))',
      foreground: 'hsl(var(--destructive-foreground))',
    },
    success: {
      DEFAULT: 'hsl(var(--success))',
      foreground: 'hsl(var(--success-foreground))',
    },
    muted: {
      DEFAULT: 'hsl(var(--muted))',
      light: 'rgba(var(--muted-light))',
      foreground: 'hsl(var(--muted-foreground))',
      border: 'hsl(var(--muted-border))',
    },
    accent: {
      DEFAULT: 'hsl(var(--accent))',
      foreground: 'hsl(var(--accent-foreground))',
    },
    popover: {
      DEFAULT: 'hsl(var(--popover))',
      foreground: 'hsl(var(--popover-foreground))',
    },
    card: {
      DEFAULT: 'hsl(var(--card))',
      foreground: 'hsl(var(--card-foreground))',
    },
    action: {
      '25': 'hsl(var(--action-25))',
      '100': 'hsl(var(--action-100))',
      '200': 'hsl(var(--action-200))',
      '300': 'hsl(var(--action-300))',
      '500': 'hsl(var(--action-500))',
      '600': 'hsl(var(--action-600))',
    },
    core: {
      '25': 'hsl(var(--core-25))',
      '200': 'hsl(var(--core-200))',
      '300': 'hsl(var(--core-300))',
    },
    neutral: {
      DEFAULT: 'hsl(var(--neutral))',
      '25': 'hsl(var(--neutral-25))',
      '50': 'hsl(var(--neutral-50))',
      '100': 'hsl(var(--neutral-100))',
      '200': 'hsl(var(--neutral-200))',
      '500': 'hsl(var(--neutral-500))',
      '600': 'hsl(var(--neutral-600))',
      '700': 'hsl(var(--neutral-700))',
      '800': 'hsl(var(--neutral-800))',
    },
    headline: {
      DEFAULT: 'hsl(var(--headline))',
    },
    placeholder: {
      DEFAULT: 'hsl(var(--placeholder))',
    },
    divider: {
      DEFAULT: 'hsl(var(--divider))',
    },
    translucent: {
      '05': 'rgba(var(--translucent-05))',
      '10': 'rgba(var(--translucent-10))',
      '25': 'rgba(var(--translucent-25))',
      '50': 'rgba(var(--translucent-50))',
      '75': 'rgba(var(--translucent-75))',
      '100': 'rgba(var(--translucent-100))',
    },
    highlight: {
      DEFAULT: 'hsl(var(--highlight))',
      '25': 'hsl(var(--highlight-25))',
      '50': 'hsl(var(--highlight-50))',
      '100': 'hsl(var(--highlight-100))',
      '200': 'hsl(var(--highlight-200))',
      '300': 'hsl(var(--highlight-300))',
      '500': 'hsl(var(--highlight-500))',
    },
    feedback: {
      DEFAULT: 'hsl(var(--feedback))',
    },
  },
  borderColor: {
    DEFAULT: 'hsl(var(--border))',
  },
  borderRadius: {
    lg: 'var(--radius)',
    md: 'calc(var(--radius) - 2px)',
    sm: 'calc(var(--radius) - 4px)',
    xl: 'calc(var(--radius) + 2px)',
    '2xl': 'calc(var(--radius) + 4px)',
  },
  boxShadow: {
    drawer: '0px -1px 4px 0px hsla(0, 0%, 0%, 0.15)', // Shadow for the sticky drawer toggle
    primary: '0px 0px 2px 0px hsla(223, 100%, 82%, 1)', // Shadow with primary color, used for focus styles
  },
  fontSize: {
    /** 69px size / 80 high / medium */
    'display-xl': ['4.3125rem', { lineHeight: '5rem', fontWeight: '500' }],
    /** 57px size / 64px high / medium */
    display: ['3.5625rem', { lineHeight: '4rem', fontWeight: '500' }],
    /** 48px size / 56px high / medium */
    h1: ['3rem', { lineHeight: '3.5rem', fontWeight: '500' }],
    /** 40px size / 48px high / medium */
    h2: ['2.5rem', { lineHeight: '3rem', fontWeight: '500' }],
    /** 33px size / 40px high / medium */
    h3: ['2.0625rem', { lineHeight: '2.25rem', fontWeight: '500' }],
    /** 28px size / 32px high / medium */
    h4: ['1.75rem', { lineHeight: '2rem', fontWeight: '500' }],
    /** 23px size / 32px high / medium */
    h5: ['1.5333333333rem', { lineHeight: '2rem', fontWeight: '500' }],

    /** 19px size / 28px high / normal */
    'body-lg': ['1.1875rem', { lineHeight: '1.75rem' }],
    /** 16px size / 24px high / normal */
    'body-md': ['1rem', { lineHeight: '1.5rem' }],
    /** 13px size / 20px high / normal */
    'body-sm': ['0.8125rem', { lineHeight: '1.25rem' }],
  },
  fontFamily: {
    sans: 'var(--font-sans)',
    display: 'var(--font-display)',
  },
  screens: {
    container: '1200px',
  },
} satisfies Config['theme']
