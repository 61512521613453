import { mapAsset } from './mappers'

import {
  TestimonialTabs,
  TestimonialTabsProps,
} from '#app/components/testimonial-tabs/testimonial-tabs'
import { TestimonialTabs as TestimonialTabsEntry } from '#app/graphql/@generated/api'
import { getValidatedProperty, isValidProperty } from '#app/utils/shared'

const mapEntryToProps = ({
  title = '',
  subtitle = '',
  background,
  testimonials = [],
}: TestimonialTabsEntry): TestimonialTabsProps => ({
  title: title ?? '',
  subtitle: subtitle ?? '',
  background: getValidatedProperty(
    background,
    'white',
    isValidProperty<TestimonialTabsProps['background']>,
  ),
  testimonials:
    testimonials?.map(t => ({
      name: t?.name || '',
      quote: t?.quote || '',
      author: t?.author || '',
      role: t?.role || '',
      logo: mapAsset(t?.logoConnection?.edges?.[0]),
      avatar: mapAsset(t?.avatarConnection?.edges?.[0]),
    })) || [],
})

export const CsTestimonialTabs = ({
  entry,
}: {
  entry: TestimonialTabsEntry
}) => <TestimonialTabs {...mapEntryToProps(entry)} />
