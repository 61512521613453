import { Icon } from '@datasnipper/icons'
import { Button } from '@datasnipper/ui/button'
import { Link } from '@remix-run/react'

import { LinkType } from '#types/shared'

export type AddOnModuleCard = {
  cardTitle: string
  heading: string
  description: string
  link: LinkType
  features: {
    text: string
  }[]
}

export function AddOnModuleSection({ cards }: { cards: AddOnModuleCard[] }) {
  return (
    <article className="flex justify-center">
      <div className="container grid gap-6 md:grid-cols-2">
        {cards.map((module, index) => {
          const { cardTitle, heading, description, features, link } = module

          return (
            <div
              key={index}
              className="overflow-hidden rounded-lg border border-neutral-50 bg-background shadow-sm"
            >
              <h3 className="bg-highlight-50 px-8 py-4 text-center text-lg font-medium text-highlight">
                {cardTitle}
              </h3>
              <section className="flex min-h-96 flex-col gap-3 p-6 px-8 text-neutral-600">
                <h2 className="mb-2 text-2xl font-medium">{heading}</h2>
                <p className="mb-4">{description}</p>
                <ul className="mb-6 space-y-2">
                  {features.map((feature, index) => (
                    <li
                      key={index}
                      className="flex items-start text-neutral-500"
                    >
                      <Icon
                        name="check"
                        className="mr-2 size-4 shrink-0 text-core-200"
                      />
                      {feature.text}
                    </li>
                  ))}
                </ul>
              </section>
              <Button
                className="flex items-center justify-start px-8 pb-12 hover:underline"
                variant="link"
                asChild
              >
                <Link
                  to={link.href}
                  reloadDocument
                  target="_blank"
                  rel="noreferrer"
                >
                  {link.children}
                  <Icon name="arrow-right" className="size-4 text-primary" />
                </Link>
              </Button>
            </div>
          )
        })}
      </div>
    </article>
  )
}
