import { Badge } from '@datasnipper/ui/badge'
import { cn } from '@datasnipper/utils/cn'

import { BadgeAttributes } from '#types/shared'

const textColorMap = {
  dark: 'primary',
  light: 'secondary',
}

type Props = {
  title: string
  subtitle?: string
  badge?: BadgeAttributes
  className?: string
  alignment?: 'center' | 'left' | 'right'
  textVariant?: keyof typeof textColorMap
}

/**
 * @deprecated This component is deprecated. Use the new Title Section component instead.
 */
export function SuperTitle({
  title,
  subtitle,
  badge,
  alignment = 'center',
  className,
  textVariant = 'dark',
}: Props) {
  return (
    <div
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className={cn(
        'mx-auto max-w-3xl space-y-4',
        `text-${alignment}`,
        className,
      )}
    >
      {badge ? (
        <Badge variant={badge?.variant}>
          <span className="px-1 py-2">{badge?.text}</span>
        </Badge>
      ) : null}

      <h2
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={cn(
          `text-${textVariant}-foreground`,
          `text-3xl sm:text-5xl `,
        )}
      >
        {title}
      </h2>

      <h3
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={cn(
          `text-${textVariant}-foreground`,
          'font-normal text-muted md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed',
        )}
      >
        {subtitle}
      </h3>
    </div>
  )
}
