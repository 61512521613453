import { Avatar } from '@datasnipper/ui/avatar'
import { Card, CardContent, CardFooter } from '@datasnipper/ui/card'
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
} from '@datasnipper/ui/carousel'
import { useEffect, useState, useRef } from 'react'

import { CompanyLogos } from '../company-logos/company-logos'

import { ImageAttributes } from '#types/shared'

interface Testimonial {
  quote: string
  name: string
  company: string
  image: ImageAttributes
}

export type AutoScrollCarouselProps = {
  title: string
  testimonials: Testimonial[]
  logos?: ImageAttributes[]
}

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (delay !== null) {
      const id = setInterval(() => savedCallback.current(), delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export function AutoScrollCarousel({
  title,
  testimonials,
  logos,
}: AutoScrollCarouselProps) {
  const [api, setApi] = useState<CarouselApi>()

  useInterval(() => {
    if (api) {
      api.scrollNext()
    }
  }, 3000)

  const duplicatedTestimonials = Array(10).fill(testimonials).flat()

  return (
    <article className="flex items-center justify-center overflow-x-hidden bg-neutral-25">
      <div className="container">
        <h2 className="text-center text-3xl font-bold">{title}</h2>
        <section className="py-0">
          <Carousel
            setApi={setApi}
            className="w-full max-w-xl py-20"
            opts={{
              align: 'start',
              loop: true,
            }}
          >
            <CarouselContent>
              {duplicatedTestimonials.map((testimonial, index) => (
                <CarouselItem key={index} className="basis-full">
                  <Card className="flex h-full min-h-72 flex-col items-start justify-between p-6 sm:h-72">
                    <CardContent>
                      <blockquote className="mb-0 text-xl italic text-neutral-600 sm:mb-6">
                        "{testimonial.quote}"
                      </blockquote>
                    </CardContent>
                    <CardFooter className="flex items-center">
                      <figure>
                        <Avatar
                          alt={testimonial.image.alt || testimonial.name}
                          src={testimonial.image.src}
                          className="mr-4 size-12"
                        />
                        <figcaption>
                          <p className="font-semibold">{testimonial.name}</p>
                          <p className="text-sm">{testimonial.company}</p>
                        </figcaption>
                      </figure>
                    </CardFooter>
                  </Card>
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
        </section>
        <section className="py-0">
          {logos ? (
            <div className="flex items-center justify-center">
              <CompanyLogos items={logos} invert={false} />
            </div>
          ) : null}
        </section>
      </div>
    </article>
  )
}
