import { cn } from '@datasnipper/utils/cn'
import { motion } from 'framer-motion'
import { useState } from 'react'

import { ImageAttributes } from '#types/shared'

export interface SlideContent {
  title: string
  description: string
  image?: ImageAttributes
  videoUrl?: string
}

export type SlidesSectionProps = {
  title: string
  subtitle: string
  slides: SlideContent[]
}

type SlideItemProps = {
  slide: SlideContent
  index: number
  currentSlide: number
  setCurrentSlide: (index: number) => void
}

const SlideItem = ({
  slide,
  index,
  currentSlide,
  setCurrentSlide,
}: SlideItemProps) => {
  const isCurrentSlide = index === currentSlide

  return (
    <button
      key={index}
      onClick={() => setCurrentSlide(index)}
      className={cn('mx-0 space-y-2 text-left', {
        'opacity-50': !isCurrentSlide,
        'relative border-l-2 border-primary pl-6 right-[26px]': isCurrentSlide,
      })}
    >
      <h3 className="text-xl font-semibold text-neutral-800 hover:cursor-pointer">
        {slide.title}
      </h3>
      {isCurrentSlide && <p>{slide.description}</p>}
    </button>
  )
}

export const SlidesSection = ({
  title,
  subtitle,
  slides,
}: SlidesSectionProps) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  return (
    <article className="flex flex-col items-center justify-center">
      <div className="max-w-screen-lg px-4 md:px-8">
        <hgroup className="mb-24 text-center">
          <h2 className="mb-2 text-3xl font-medium">{title}</h2>
          <p>{subtitle}</p>
        </hgroup>

        <section className="flex flex-col items-center border-l border-neutral-50 py-0 md:flex-row">
          <aside className="order-1 space-y-8 px-6 md:-order-1 md:w-5/12">
            {slides.map((slide, index) => (
              <SlideItem
                key={index}
                slide={slide}
                index={index}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
              />
            ))}
          </aside>

          <figure className="my-5 w-full md:mt-0 md:w-7/12">
            {slides.map((slide, index) => (
              <motion.div
                key={index}
                style={{ display: index === currentSlide ? 'block' : 'none' }}
                animate={{ opacity: index === currentSlide ? 100 : 0 }}
                transition={{ ease: 'easeIn', duration: 0.35 }}
              >
                {slide?.videoUrl ? (
                  <video
                    autoPlay
                    loop={true}
                    muted={true}
                    className="rounded-lg"
                  >
                    <source src={slide?.videoUrl} />
                  </video>
                ) : slide?.image ? (
                  <img
                    src={slide?.image?.src}
                    alt={`Illustration for ${slide?.title}`}
                    className="size-full object-cover"
                  />
                ) : null}
              </motion.div>
            ))}
          </figure>
        </section>
      </div>
    </article>
  )
}
