import { mapAsset } from './mappers.ts'

import { FeatureGrid } from '#app/components/feature-grid/feature-grid.tsx'
import { type FeatureGrid as FeatureGridEntry } from '#app/graphql/@generated/api.ts'

export function CsFeatureGrid({ entry }: { entry: FeatureGridEntry }) {
  const image = mapAsset(entry.background_imageConnection?.edges?.[0])

  const video = mapAsset(entry.videoConnection?.edges?.[0])

  return (
    <FeatureGrid
      title={entry.main_title ?? ''}
      subtitle={entry.subtitle ?? ''}
      highlightedWord={entry.highlighted_word ?? ''}
      demo={{
        posterSrc: image.src,
        mp4Src: video.src,
      }}
      reviewPosition={entry.review_position ?? 'left'}
      reviewProps={
        entry.reviews
          ? {
              author: entry.reviews.author ?? '',
              authorTitle: entry.reviews.author_title ?? '',
              body: entry.reviews.body ?? '',
              logo: mapAsset(
                entry.reviews.company_logo?.imageConnection?.edges?.[0],
              ),
            }
          : undefined
      }
    />
  )
}
