import { useEffect, useState } from 'react'

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const mediaMatch = window.matchMedia(query)

    // Initial check for matches
    setMatches(mediaMatch.matches)

    // Define the event listener callback
    const handler = (e: MediaQueryListEvent) => setMatches(e.matches)

    // Add listener
    mediaMatch.addEventListener('change', handler)

    // Cleanup function
    return () => {
      mediaMatch.removeEventListener('change', handler)
    }
  }, [query])

  return matches
}
