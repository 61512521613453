type TransformerOptions = {
  width?: number
  height?: number
  quality?: number
  crop?: string
}

// Produces something like:
// https://images.contentstack.io/v3/assets/blteae40eb499811073/bltc5064f36b5855343/59e0c41ac0eddd140d5a8e3e/owl.jpg?auto=webp&width=800&height=600&quality=80

function buildImgUrl(
  url: string,
  { width, height, quality = 80 }: TransformerOptions,
) {
  const params = new URLSearchParams({
    auto: 'avif',
    quality: quality.toString(),
  })

  if (width) {
    params.set('width', width.toString())
  }

  if (height) {
    params.set('height', height.toString())
  }

  return `${url}?${params.toString()}`
}

type ImageOptions = {
  widths: number[]
  sizes: string[]
  transformations?: TransformerOptions
}

export function getImageProps(
  url: string,
  { widths, sizes, transformations }: ImageOptions,
) {
  const averageSize = Math.ceil(widths.reduce((a, s) => a + s) / widths.length)

  return {
    src: buildImgUrl(url, {
      width: averageSize,
      ...transformations,
    }),
    srcSet: widths
      .map(width =>
        [
          `${buildImgUrl(url, {
            width: width,
            ...transformations,
          })} ${width}w`,
        ].join(' '),
      )
      .join(', '),
    sizes: sizes.join(', '),
  } as const
}
