import { CsAddOnModuleSection } from './add-on-module-section.tsx'
import { CsAutoScrollCarousel } from './auto-scroll-carousel.tsx'
import { CsCardBanner } from './card-banner.tsx'
import { CsCarouselBanner } from './carousel.tsx'
import { CsCompanyLogosSection } from './company-logos-section.tsx'
import { CsCoreNumbers } from './core-numbers.tsx'
import { CsCustomerStories } from './customer-stories.tsx'
import { CsFeatureBanner } from './feature-banner.tsx'
import { CsFeatureComparisonTable } from './feature-comparison-table.tsx'
import { CsFeatureGrid } from './feature-grid.tsx'
import { CsFeatureSection } from './feature-section.tsx'
import { CsHeroHeader } from './hero-header.tsx'
import { CsHero } from './hero.tsx'
import { CsLinkButton } from './link-button.tsx'
import { CsLinkPreviewCard } from './link-preview-card.tsx'
import { CsPricingTable } from './pricing-table.tsx'
import { CsProductAnnouncementCard } from './product-announcement-card.tsx'
import { CsReviewsSection } from './reviews-section.tsx'
import { CsServiceOfferings } from './service-offerings.tsx'
import { CsSlidesSection } from './slides-section.tsx'
import { CsStatsDashboard } from './stats-dashboard.tsx'
import { CsSuperHero } from './super-hero.tsx'
import { CsTabLayoutSection } from './tab-layout-section.tsx'
import { CsTestimonialTabs } from './testimonial-tabs.tsx'
import { CsTitleSection } from './title-section.tsx'
import { CsVerticals } from './verticals.tsx'

import { PageBlocks } from '#app/graphql/@generated/api.ts'
import { createCslp } from '#app/utils/api.ts'

export enum ComponentTypes {
  SuperHero = 'SuperHeroBanner',
  HeroBanner = 'HeroBanner',
  CoreNumbers = 'CoreNumbers',
  CardBanner = 'CardBanner',
  Verticals = 'Verticals',
  TabLayout = 'TabLayout',
  FeatureGrid = 'FeatureGrid',
  CarouselBanner = 'CarouselBanner',
  LinkPreviewCard = 'LinkPreviewCard',
  LinkButton = 'LinkButton',
  AddOnModuleSection = 'AddOnModules',
  Hero = 'Hero',
  HeroHeader = 'HeroHeader',
  PricingTable = 'PricingTable',
  ServiceOfferings = 'ServiceOfferings',
  FeatureComparisonTable = 'FeatureComparisonTable',
  AutoScrollCarousel = 'AutoScrollCarousel',
  ReviewsSection = 'ReviewsSection',
  CompanyLogosSection = 'CompanyLogosSection',
  StatsDashboard = 'StatsDashboard',
  SlidesSection = 'SlidesSection',
  FeatureSection = 'FeatureSection',
  CustomerStories = 'CustomerStories',
  ProductAnnouncementCard = 'ProductAnnouncementCard',
  FeatureBanner = 'FeatureBanner',
  TitleSection = 'TitleSection',
  TestimonialTabs = 'TestimonialTabs',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const components: Record<ComponentTypes, React.ComponentType<any>> = {
  [ComponentTypes.SuperHero]: CsSuperHero,
  [ComponentTypes.HeroBanner]: () => null,
  [ComponentTypes.CoreNumbers]: CsCoreNumbers,
  [ComponentTypes.CardBanner]: CsCardBanner,
  [ComponentTypes.Verticals]: CsVerticals,
  [ComponentTypes.TabLayout]: CsTabLayoutSection,
  [ComponentTypes.FeatureGrid]: CsFeatureGrid,
  [ComponentTypes.CarouselBanner]: CsCarouselBanner,
  [ComponentTypes.LinkPreviewCard]: CsLinkPreviewCard,
  [ComponentTypes.LinkButton]: CsLinkButton,
  [ComponentTypes.AddOnModuleSection]: CsAddOnModuleSection,
  [ComponentTypes.Hero]: CsHero,
  [ComponentTypes.PricingTable]: CsPricingTable,
  [ComponentTypes.ServiceOfferings]: CsServiceOfferings,
  [ComponentTypes.FeatureComparisonTable]: CsFeatureComparisonTable,
  [ComponentTypes.AutoScrollCarousel]: CsAutoScrollCarousel,
  [ComponentTypes.HeroHeader]: CsHeroHeader,
  [ComponentTypes.ReviewsSection]: CsReviewsSection,
  [ComponentTypes.CompanyLogosSection]: CsCompanyLogosSection,
  [ComponentTypes.StatsDashboard]: CsStatsDashboard,
  [ComponentTypes.SlidesSection]: CsSlidesSection,
  [ComponentTypes.FeatureSection]: CsFeatureSection,
  [ComponentTypes.CustomerStories]: CsCustomerStories,
  [ComponentTypes.ProductAnnouncementCard]: CsProductAnnouncementCard,
  [ComponentTypes.FeatureBanner]: CsFeatureBanner,
  [ComponentTypes.TitleSection]: CsTitleSection,
  [ComponentTypes.TestimonialTabs]: CsTestimonialTabs,
}

export function CsComponent({
  entry,
  preview,
}: {
  entry: PageBlocks
  preview: boolean
}) {
  if (!entry.__typename) {
    console.error('No __typename found on entry: ', entry)
    return null
  }

  const Component = components[entry.__typename as ComponentTypes] // Type assertion

  if (!Component) {
    console.error('Unknown component found: ', entry.__typename)
    return null
  }

  if (preview) {
    return (
      // @ts-expect-error - cslp is not a valid attribute
      <div data-cslp={createCslp(entry, 'title')}>
        <Component entry={entry} />
      </div>
    )
  }

  return <Component entry={entry} />
}
