import sharedConfig from '@datasnipper/tailwind-config'
import { type Config } from 'tailwindcss'

const config: Config = {
  content: ['./app/**/*.{ts,tsx,jsx,js}'],
  presets: [sharedConfig],
  darkMode: 'class',
  theme: {
    extend: {
      colors: {
        'gradient-start': 'rgba(0, 22, 65, 0)',
        'gradient-end': '#001641 76%',
      },
      // Using mobile sizes and overriding for larger screens in app/styles/tailwind.css
      fontSize: {
        // Desktop sizes
        'display-xl-desktop': [
          '4.3125rem',
          { lineHeight: '5rem', fontWeight: '500' },
        ],
        'display-desktop': [
          '3.5625rem',
          { lineHeight: '4rem', fontWeight: '500' },
        ],
        'h1-desktop': ['3rem', { lineHeight: '3.5rem', fontWeight: '500' }],
        'h2-desktop': ['2.5rem', { lineHeight: '3rem', fontWeight: '500' }],
        'h3-desktop': [
          '2.0625rem',
          { lineHeight: '2.25rem', fontWeight: '500' },
        ],
        'h4-desktop': ['1.75rem', { lineHeight: '2rem', fontWeight: '500' }],
        'h5-desktop': [
          '1.5333333333rem',
          { lineHeight: '2rem', fontWeight: '500' },
        ],

        // Mobile sizes
        'display-xl': ['3rem', { lineHeight: '3.5rem', fontWeight: '500' }],
        display: ['2.5rem', { lineHeight: '3rem', fontWeight: '500' }],
        h1: ['2.063rem', { lineHeight: '2.25rem', fontWeight: '500' }],
        h2: ['1.75rem', { lineHeight: '2rem', fontWeight: '500' }],
        h3: ['1.438rem', { lineHeight: '2rem', fontWeight: '500' }],
        h4: ['1.188rem', { lineHeight: '2rem', fontWeight: '500' }],
        h5: ['1rem', { lineHeight: '2rem', fontWeight: '500' }],
      },
      screens: {
        container: '1280px',
      },
    },
  },
}

export default config
