import { mapAsset } from './mappers'

import {
  CustomerStories,
  CustomerStoriesProps,
} from '#app/components/customer-stories/customer-stories'
import {
  CustomerStoriesCustomerStoryCards,
  CustomerStories as CustomerStoriesEntry,
  Maybe,
} from '#app/graphql/@generated/api'

export const CsCustomerStories = ({
  entry,
}: {
  entry: CustomerStoriesEntry
}) => {
  const mapCustomerStoriesCardsToProps = (
    customerStoriesCards: Maybe<CustomerStoriesCustomerStoryCards>[],
  ): CustomerStoriesProps['customerStoryCards'] =>
    customerStoriesCards.map(card => ({
      name: card?.name || '',
      description: card?.description || '',
      vertical: card?.vertical || '',
      logo: mapAsset(card?.logoConnection?.edges?.[0]),
      link: {
        title: card?.link?.title || '',
        href: card?.link?.href || card?.name || '',
      },
    }))

  return entry?.customer_story_cards ? (
    <CustomerStories
      title={entry.title || ''}
      subtitle={entry.subtitle || ''}
      customerStoryCards={mapCustomerStoriesCardsToProps(
        entry?.customer_story_cards || [],
      )}
    />
  ) : null
}
