import ContentstackLivePreview from '@contentstack/live-preview-utils'
import { GraphQLClient } from 'graphql-request'
import isEmpty from 'lodash/isEmpty'

import { Page, getSdk } from '#app/graphql/@generated/api.ts'

let client: GraphQLClient | null = null

function getApiUrl(preview: boolean) {
  const host = preview
    ? 'eu-graphql-preview.contentstack.com'
    : 'eu-graphql.contentstack.com'

  return `https://${host}/stacks/${process.env.CONTENTSTACK_API_KEY}?environment=${process.env.CONTENTSTACK_ENVIRONMENT}`
}

export function isPreview() {
  return Boolean(ContentstackLivePreview.hash)
}

// Creates a CLSP string for contentstack like: page.blt01a6a6809ad0882b.en-us.componentsConnection
export function createCslp<
  TEntry extends {
    system?: {
      uid?: string | null
      content_type_uid?: string | null
      locale?: string | null
    } | null
  },
>(entry: TEntry, field: string) {
  const system = entry.system
  if (!system) return
  return `${system.content_type_uid}.${system.uid}.${system.locale}.${field}`
}

export function getClient({ preview }: { preview: boolean }) {
  const url = getApiUrl(preview)

  const headers = new Headers({
    'Content-Type': 'application/json',
    access_token: process.env.CONTENTSTACK_DELIVERY_TOKEN ?? '',
  })

  if (!client) {
    client = new GraphQLClient(url, { headers })
  }

  if (preview) {
    headers.set('preview_token', process.env.CONTENTSTACK_PREVIEW_TOKEN ?? '')
    headers.set('live_preview', ContentstackLivePreview.hash)
    headers.set(
      'Authorization',
      `Bearer ${process.env.CONTENTSTACK_MANAGEMENT_TOKEN}`,
    )
  }

  client.setHeaders(headers)
  client.setEndpoint(url)

  return getSdk(client)
}

export async function getNavigation({ preview }: { preview: boolean }) {
  try {
    const client = getClient({ preview })
    const header = await client.getHeader()
    return header.data.all_header?.items?.[0]
  } catch (error) {
    console.error('Error fetching navigation', error)
  }
}

export async function getFooter({ preview }: { preview: boolean }) {
  try {
    const client = getClient({ preview })
    const footer = await client.getFooter()
    return footer.data.all_footer?.items?.[0]
  } catch (error) {
    console.error('Error fetching footer', error)
  }
}

/**
 * Flattens the blocks array.
 * @example [{ section1: { components: [1, 2] } }, { section2: { components: [3, 4] } }] => [1, 2, 3, 4]
 */
const flattenBlocks = (array: Page['blocks']) => {
  if (!array) return

  return array.flatMap(obj => {
    if (!obj) return

    return Object.values(obj).flatMap(section => {
      if (!section?.components) return

      return section.components
    })
  })
}

export async function getPageBySlug({
  preview,
  slug,
}: {
  preview: boolean
  slug: string
}) {
  try {
    const client = getClient({ preview })

    const [pageData, blocksData] = await Promise.all([
      client.getPageByUrl({ url: slug }),
      client.getPageBlockComponentsByUrl({ url: slug }),
    ])

    const blocks = flattenBlocks(blocksData?.data?.all_page?.items?.[0]?.blocks)
    const pageItems = pageData?.data?.all_page?.items?.[0]

    return {
      ...pageItems,
      ...(!isEmpty(blocks) ? { blocks } : null),
    }
  } catch (error) {
    console.error(`Error fetching page for slug: ${slug}`, error)
    if (error instanceof Error) {
      throw new Error(`Failed to fetch page data: ${error.message}`)
    }
    throw new Error('An unknown error occurred while fetching page data')
  }
}

type DefaultVariables = {
  preview: boolean
  locale: string
}

export async function getUrlsForSitemap({ locale, preview }: DefaultVariables) {
  try {
    const client = getClient({ preview })
    const pages = await client.getPagesForSitemap({ locale })
    return pages.data.all_page?.items
      ?.filter(Boolean)
      .map(({ url }) => url)
      .filter(Boolean)
  } catch (error) {
    console.error('Error fetching pages for sitemap', error)
  }
}
