import { Icon } from '@datasnipper/icons'

import { LinkButton } from '#app/components/link-button/link-button.js'
import { type LinkButton as LinkButtonEntry } from '#app/graphql/@generated/api.ts'
import { isValidIcon } from '#app/utils/isValidIcon.js'

export function CsLinkButton({ entry }: { entry: LinkButtonEntry }) {
  return (
    <LinkButton
      className="justify-start gap-0"
      link={{
        children: (
          <>
            {entry.link?.title}
            {isValidIcon(entry.icon) && (
              <Icon name={entry.icon ?? ''} size="md" />
            )}
          </>
        ),
        href: entry.link?.href ?? '',
      }}
      openInNewTab={entry.open_in_new_tab ?? false}
    />
  )
}
