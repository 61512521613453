import { Review } from '@datasnipper/ui/review'
import { cn } from '@datasnipper/utils/cn'

import { CompanyLogos } from '../company-logos/company-logos.tsx'

import { useCurrentScreenSize } from '#app/hooks/useCurrentScreenSize/useCurrentScreenSize.js'
import { getImageProps } from '#app/utils/images.js'
import { ImageAttributes, ReviewType } from '#types/shared.ts'

export type Props = {
  title: string
  subtitle: string
  backgroundOverlay?: ImageAttributes
  primaryActionSlot?: React.ReactNode
  secondaryActionSlot?: React.ReactNode
  logos?: ImageAttributes[]
  reviews?: ReviewType[]
  banner?: {
    src: string
    alt: string
  }
}

export function SuperHero({
  title,
  subtitle,
  logos,
  reviews,
  primaryActionSlot,
  secondaryActionSlot,
  banner,
}: Props) {
  const { isMobile } = useCurrentScreenSize()

  return (
    <section
      style={{
        backgroundPosition: `0 -6rem, 85% 100%`,
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundSize: 'auto, cover',
      }}
      className={cn(
        `bg-none lg:bg-[url(/images/top-corner-pattern.svg),_url(/images/pattern.svg)]`,
        `top-0 z-50 bg-neutral-800 bg-cover bg-no-repeat`,
        `flex flex-col items-center justify-between pb-0 pt-32 md:pt-56`,
      )}
    >
      <div className="flex max-w-screen-lg flex-col items-center justify-center gap-4 px-4 text-center md:gap-10 lg:flex-row lg:items-center lg:text-balance lg:px-8 lg:text-left">
        <h1 className="mt-12 w-fit max-w-lg text-pretty text-display text-primary-foreground md:mt-0 ">
          {title}
        </h1>

        <div className="mt-2 flex flex-col items-center justify-center gap-6 lg:items-start lg:justify-start">
          <p className="text-pretty text-lg text-neutral">{subtitle}</p>

          {primaryActionSlot ? (
            <div className="flex w-full flex-col items-center justify-center gap-5 sm:w-auto sm:flex-row lg:justify-start">
              {primaryActionSlot}
              {secondaryActionSlot}
            </div>
          ) : null}
        </div>
      </div>

      {logos ? (
        <div className="my-12 px-4 lg:px-8">
          <CompanyLogos items={logos} />
        </div>
      ) : null}

      <div className="relative mt-8 flex size-full flex-col items-center overflow-hidden md:mt-12">
        {banner ? (
          <img
            loading="eager"
            className="mb-10 w-4/5 max-w-screen-lg object-cover"
            alt={banner.alt}
            {...getImageProps(banner.src, {
              widths: [1000, 2000],
              sizes: ['(min-width: 1360px) 1024px', 'calc(75.38vw + 14px)'],
            })}
          />
        ) : null}
        <div className="absolute inset-x-0 bottom-0 flex min-h-52 items-center justify-center bg-gradient-to-b from-gradient-start to-gradient-end sm:min-h-96">
          <div className="absolute bottom-0 flex gap-5 p-2 pb-8">
            {reviews?.map((review, index) => (
              <Review
                key={index}
                imgSrc={review.src}
                imgAlt={review.alt}
                score={review.score}
                size={isMobile ? 'sm' : 'md'}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
