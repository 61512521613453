import { cn } from '@datasnipper/utils/cn'
import { useInView } from 'framer-motion'
import { useRef } from 'react'

import { useCurrentScreenSize } from '#app/hooks/useCurrentScreenSize/useCurrentScreenSize.js'
import { getImageProps } from '#app/utils/images.js'
import { ImageAttributes } from '#types/shared'

export type Props = {
  title: string
  highlightedWord: string
  subtitle: string
  demo: {
    posterSrc: string
    mp4Src?: string
    webmSrc?: string
  }
  backgroundImage?: ImageAttributes
  reviewPosition?: string // 'left' | 'right'
  reviewProps?: {
    body: string
    author: string
    authorTitle: string
    logo: ImageAttributes
  }
}

export function FeatureGrid({
  title,
  highlightedWord,
  subtitle,
  backgroundImage,
  demo,
  reviewProps,
  reviewPosition,
}: Props) {
  const reviewRight = reviewPosition === 'right'
  const ref = useRef(null)

  const isInView = useInView(ref, { once: true })

  const { isMobile } = useCurrentScreenSize()

  return (
    <section
      style={{ backgroundImage: `url(${backgroundImage?.src})` }}
      className={cn(
        `container`,
        reviewRight ? 'bg-left lg:flex-row-reverse' : 'bg-right lg:flex-row',
        `flex flex-col gap-14`,
        `space-y-8 md:space-y-0`,
        `bg-contain bg-no-repeat`,
        `mx-auto overflow-hidden`,
      )}
    >
      <div className={cn(`flex lg:w-2/6`, reviewRight ? 'md:pl-8' : 'md:pr-8')}>
        <div>
          <div className="font-display text-3xl font-medium">
            <span>
              {title} <span className="text-core-200">{highlightedWord}</span>
            </span>
          </div>
          <p className="mt-4 text-neutral-700">{subtitle}</p>
          <div className="mt-8 rounded-[1rem] bg-action-25 p-6">
            <p className="font-normal italic leading-7 text-neutral-700">
              {reviewProps?.body}
            </p>
            <div className="mt-4 flex flex-col gap-8">
              <div>
                <p className="text-lg font-bold">{reviewProps?.author}</p>
                <p className="text-sm font-medium">
                  {reviewProps?.authorTitle}
                </p>
              </div>
              <div>
                <img
                  className="h-10 opacity-60 grayscale"
                  src={reviewProps?.logo.src}
                  alt={reviewProps?.logo.alt}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={ref} className="flex-1 lg:w-4/6">
        {(demo.mp4Src || demo.webmSrc) && isInView && !isMobile ? (
          <video
            autoPlay={!isMobile}
            loop={true}
            muted={true}
            className="hidden rounded-lg md:block lg:shadow-2xl"
          >
            {demo.mp4Src ? <source src={demo.mp4Src} /> : null}
            {demo.webmSrc ? <source src={demo.webmSrc} /> : null}
          </video>
        ) : null}
        {demo.posterSrc ? (
          <img
            alt="Product demo"
            className="w-full rounded-lg md:hidden"
            {...getImageProps(demo.posterSrc, {
              widths: [500, 2000],
              sizes: ['(max-width: 600px) 500px', '2000px'],
              transformations: { quality: 100 },
            })}
          />
        ) : null}
      </div>
    </section>
  )
}
