import { cn } from '@datasnipper/utils/cn'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

const buttonVariants = cva(
  cn(
    'inline-flex items-center justify-center gap-x-2 whitespace-nowrap rounded-lg border text-base font-medium leading-6 transition-colors',
    'ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
    'disabled:pointer-events-none disabled:border-muted-border disabled:bg-muted disabled:text-muted-foreground',
  ),
  {
    variants: {
      variant: {
        primary:
          'border-translucent-05 bg-primary text-white hover:bg-primary-light',
        secondary:
          'border-translucent-100 bg-translucent-05 text-translucent-100 hover:border-translucent-05',
        destructive:
          'border-translucent-05 bg-destructive text-white hover:bg-destructive/80',
        outline:
          'border-translucent-10 bg-transparent text-translucent-100 hover:bg-translucent-05',
        ghost:
          'border-transparent bg-transparent text-translucent-100 hover:bg-translucent-05 disabled:border-transparent disabled:bg-transparent disabled:text-muted',
        link: 'border-transparent text-primary hover:text-primary-light disabled:border-transparent disabled:bg-transparent disabled:text-muted',
      },
      size: {
        sm: 'px-4 py-2',
        primary: 'px-6 py-3',
        lg: 'px-8 py-4',
        icon: 'size-10',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'primary',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size = 'primary', asChild = false, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          asChild ? 'no-underline' : '',
        )}
        ref={ref}
        {...props}
      />
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
