import { IconName } from '@datasnipper/icons'

import { Maybe } from '#app/graphql/@generated/api.js'

/**
 * @deprecated Replace with getValidatedProperty
 */
export const isValidIcon = (
  value?: string | Maybe<string>,
): value is IconName => !!value
