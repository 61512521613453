import { mapAsset } from './mappers'

import {
  ReviewsSection,
  ReviewsSectionProps,
} from '#app/components/reviews-section/reviews-section'
import { ReviewsSection as ReviewsSectionEntry } from '#app/graphql/@generated/api'

const mapEntryToProps = (entry: ReviewsSectionEntry): ReviewsSectionProps => ({
  title: entry.title ?? '',
  subtitle: entry.subtitle ?? '',
  reviews:
    entry.reviews?.map(review => ({
      ...mapAsset(review?.logoConnection?.edges?.[0]),
      score: review?.score ?? 0,
      text: review?.text ?? '',
    })) ?? [],
})

export const CsReviewsSection = ({ entry }: { entry: ReviewsSectionEntry }) => (
  <ReviewsSection {...mapEntryToProps(entry)} />
)
