import { Button } from '@datasnipper/ui/button'
import { Link } from '@remix-run/react'
import { useState } from 'react'

import { mapAsset } from './mappers.ts'

import { PlayVideo } from '#app/components/play-video/play-video.js'
import { SuperHero } from '#app/components/super-hero/super-hero.tsx'
import { SuperHeroBanner } from '#app/graphql/@generated/api.ts'

export function CsSuperHero({ entry }: { entry: SuperHeroBanner }) {
  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <>
      <SuperHero
        title={entry.main_text ?? ''}
        subtitle={entry.subtitle ?? ''}
        banner={mapAsset(entry.bannerConnection?.edges?.[0])}
        logos={(entry.client_logosConnection?.edges ?? []).map(mapAsset)}
        primaryActionSlot={
          entry.primary_action_button?.href ? (
            <Button className="w-full sm:w-auto" asChild>
              <Link
                reloadDocument
                to={entry.primary_action_button.href}
                prefetch="intent"
              >
                {entry.primary_action_button.title}
              </Link>
            </Button>
          ) : null
        }
        secondaryActionSlot={
          entry.secondary_action_button?.href && entry.vimeo_id ? (
            <Button
              variant="outline"
              className="w-full border-primary-foreground text-primary-foreground hover:bg-primary-foreground/10 sm:w-auto"
              asChild
              onClick={() => setDialogOpen(true)}
            >
              <Link to="#">{entry.secondary_action_button.title}</Link>
            </Button>
          ) : null
        }
        reviews={entry.reviews?.filter(Boolean).map(review => ({
          ...mapAsset(review.logoConnection?.edges?.[0]),
          score: review.score ?? 5,
        }))}
      />
      <PlayVideo
        vimeoId={entry.vimeo_id ?? ''}
        onOpenChange={open => setDialogOpen(open)}
        open={dialogOpen}
      />
    </>
  )
}
