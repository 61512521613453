import {
  FeatureComparisonTable,
  FeatureComparisonTableProps,
} from '#app/components/feature-comparison/feature-comparison'
import { FeatureComparisonTable as FeatureComparisonTableEntry } from '#app/graphql/@generated/api'

// We need this because of how it comes in from Contentstack
const parseBoolean = (value: string): boolean | string => {
  if (value === 'True') return true
  if (value === 'False') return false
  return value
}

const transformEntryToProps = (
  entry: FeatureComparisonTableEntry,
): FeatureComparisonTableProps => {
  return {
    title: entry.title ?? '',
    tableCaption: entry.table_caption ?? '',
    featureNames:
      entry.feature_names?.filter((name): name is string => !!name) ?? [],
    features: (
      entry.features?.filter(
        (category): category is NonNullable<typeof category> => !!category,
      ) ?? []
    ).map(category => ({
      name: category.name ?? '',
      features:
        category.features
          ?.filter(
            (feature): feature is NonNullable<typeof feature> => !!feature,
          )
          .map(feature => ({
            name: feature.name ?? '',
            info: feature.info,
            basic: parseBoolean(feature?.basic ?? ''),
            professional: parseBoolean(feature?.professional ?? ''),
            enterprise: parseBoolean(feature?.enterprise ?? ''),
          })) ?? [],
    })) as FeatureComparisonTableProps['features'],
  }
}

export function CsFeatureComparisonTable({
  entry,
}: {
  entry: FeatureComparisonTableEntry
}) {
  const props = transformEntryToProps(entry)

  return <FeatureComparisonTable {...props} />
}
