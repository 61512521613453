import {
  PricingTable,
  PricingTableProps,
  Tier,
} from '#app/components/pricing-table/pricing-table'
import {
  Maybe,
  PricingTable as PricingTableEntry,
  PricingTableTiers,
} from '#app/graphql/@generated/api'

export function CsPricingTable({ entry }: { entry: PricingTableEntry }) {
  const mapEntryToProps = (
    tiers: Maybe<Array<Maybe<PricingTableTiers>>> | undefined = [],
  ): PricingTableProps => {
    return {
      tiers: tiers?.map(tier => ({
        name: tier?.name ?? '',
        audience: tier?.audience ?? '',
        description: tier?.description?.trim() ?? '',
        teamSize: {
          description: tier?.team_size?.description ?? '',
          ...(tier?.team_size?.tooltip && { tooltip: tier.team_size.tooltip }),
        },
        features: [
          {
            title: tier?.features?.[0]?.title || '',
            items: [
              tier?.description?.trim() ?? '',
              tier?.team_size?.description ?? '',
              ...(tier?.features?.[0]?.items ?? []),
            ],
          },
          ...(tier?.features?.slice(1) || []),
        ],
        ...(tier?.badge && { badge: tier.badge }),
        link: {
          href: tier?.link?.href ?? '',
          children: tier?.link?.title ?? '',
        },
      })) as Tier[],
    }
  }

  const pricingTableProps = mapEntryToProps(entry.tiers)

  return <PricingTable {...pricingTableProps} />
}
