import { Icon, IconName } from '@datasnipper/icons'
import { Button } from '@datasnipper/ui/button'
import { Link } from '@remix-run/react'

import { LinkAttributes } from '#types/shared'

export interface FeatureCard {
  icon: IconName
  title: string
  description: string
}

export type FeatureSectionProps = {
  title: string
  subtitle: string
  link: LinkAttributes
  features: FeatureCard[]
}

const FeatureCard = ({ icon, title, description }: FeatureCard) => (
  <div className="flex flex-col items-start">
    <Icon className="mb-4 size-8 self-start text-highlight" name={icon} />
    <h5 className="mb-2 font-medium">{title}</h5>
    <p className="text-lg text-body">{description}</p>
  </div>
)

export const FeatureSection = ({
  title,
  subtitle,
  link,
  features,
}: FeatureSectionProps) => (
  <article className="flex justify-center">
    <div className="container">
      <section className="mb-12 flex flex-col items-start justify-between gap-5 py-0 md:flex-row md:items-center md:gap-0">
        <hgroup>
          <h2 className="mb-2 font-medium">{title}</h2>
          <p className="text-xl text-body">{subtitle}</p>
        </hgroup>
        <Link to={link.href}>
          <Button variant="outline">{link.title}</Button>
        </Link>
      </section>
      <section className="grid grid-cols-1 gap-8 py-0 md:grid-cols-3">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </section>
    </div>
  </article>
)
