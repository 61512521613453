import { Card, CardContent } from '@datasnipper/ui/card'
import { Grid } from '@datasnipper/ui/grid'
import { cn } from '@datasnipper/utils/cn'

import { GradientType } from '#types/shared'

interface Stat {
  metric: string
  description: string
  color?: GradientType
}

export type StatsDashboardProps = {
  title?: string
  subtitle?: string
  stats: Stat[]
}

const gradientStyles = {
  core: {
    background:
      'linear-gradient(298deg, rgba(132, 245, 229, 0.16) -0.06%, rgba(132, 245, 229, 0.00) 46.08%), rgba(254, 253, 255, 0.05)',
    text: 'linear-gradient(90deg, #00D9C6 0.2%, #1CCCCF 99.79%)',
  },
  action: {
    background:
      'linear-gradient(294deg, rgba(162, 188, 255, 0.16) 0.06%, rgba(162, 188, 255, 0.00) 52.47%), rgba(254, 253, 255, 0.05)',
    text: 'linear-gradient(90deg, #44B7DB -0.33%, #779DEB 100.33%)',
  },
  highlight: {
    background:
      'linear-gradient(304deg, rgba(206, 170, 253, 0.16) 0%, rgba(206, 170, 253, 0.00) 57.89%), rgba(254, 253, 255, 0.05)',
    text: 'linear-gradient(90deg, #9C8AF7 -0.05%, #B77CFF 100.05%)',
  },
}

export const StatsDashboard = ({
  title,
  subtitle,
  stats,
}: StatsDashboardProps) => (
  <article className="flex justify-center text-center">
    <div className="container">
      <h3 className="mb-4">{title}</h3>
      <p className="mb-12 text-lg">{subtitle}</p>

      <Grid
        nested
        rowGap
        className="grid-cols-1 place-items-center gap-x-20 lg:grid-cols-3"
      >
        {stats.map((stat, index) => (
          <StatCard key={index} {...stat} />
        ))}
      </Grid>
    </div>
  </article>
)

const StatCard = ({ metric, description, color }: Stat) => {
  const style = gradientStyles[color ?? 'core']

  return (
    <Card className="h-48 w-full overflow-hidden lg:h-52 lg:max-w-80 xl:max-w-96">
      <CardContent
        style={{ background: style.background }}
        className={cn(
          'flex flex-col items-center justify-between',
          'h-full bg-gradient-to-br p-8',
        )}
      >
        <h2
          style={{ backgroundImage: style.text }}
          className={cn(
            'mb-2 text-display',
            'bg-gradient-to-r bg-clip-text text-transparent',
          )}
        >
          {metric}
        </h2>
        <p>{description}</p>
      </CardContent>
    </Card>
  )
}
