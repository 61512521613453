import { Icon } from '@datasnipper/icons'
import { Button } from '@datasnipper/ui/button'
import { cn } from '@datasnipper/utils/cn'
import { Link } from '@remix-run/react'

import { LinkType } from '#types/shared'

type Props = {
  link: LinkType
  openInNewTab?: boolean
  className?: string
  showIcon?: boolean
}

export function LinkButton({
  link,
  openInNewTab = false,
  className,
  showIcon = false,
}: Props) {
  return (
    <Button
      className={cn('flex items-center gap-x-2 px-0', className)}
      variant="link"
      asChild
    >
      <Link
        reloadDocument
        to={link.href}
        target={openInNewTab ? '_blank' : '_self'}
        rel="noreferrer"
      >
        {link.children}
        {showIcon ? (
          <Icon name="arrow-right" className="size-4 text-primary" />
        ) : null}
      </Link>
    </Button>
  )
}
