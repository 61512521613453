import { mapAsset } from './mappers'

import {
  CompanyLogosSection,
  CompanyLogosSectionProps,
} from '#app/components/company-logos-section/company-logos-section'
import { CompanyLogosSection as CompanyLogosSectionEntry } from '#app/graphql/@generated/api'
import { getValidatedProperty, isValidProperty } from '#app/utils/shared'

const mapEntryToProps = (
  entry: CompanyLogosSectionEntry,
): CompanyLogosSectionProps => ({
  background: getValidatedProperty(
    entry.background,
    'gray',
    isValidProperty<CompanyLogosSectionProps['background']>,
  ),
  items: (entry.itemsConnection?.edges ?? []).map(mapAsset),
})

export function CsCompanyLogosSection({
  entry,
}: {
  entry: CompanyLogosSectionEntry
}) {
  if (!entry) return null

  return <CompanyLogosSection {...mapEntryToProps(entry)} />
}
