import { Maybe } from 'graphql/jsutils/Maybe'

/**
 * Checks if a given value is a valid property of a specified type.
 *
 * @template T - A type that may be a string, or `undefined`.
 *
 * @param {unknown} [value] - The value to be validated as a potential property.
 *
 * @returns {value is T} - Returns `true` if the value is valid and defined; otherwise, returns `false`.
 */
export const isValidProperty = <T extends Maybe<string> | undefined>(
  value?: unknown,
): value is T => !!value

/**
 * Type guard function that returns a validated property value,
 * or a default value if the validation fails.
 *
 * @template T - Generic representing the expected type of the validated value.
 *
 * @param {unknown} value - The value to be validated.
 * @param {T} defaultValue - The default value to return if the validation fails.
 * @param {(value: unknown) => value is T} validator - A predicate function that determines if the value is of the expected type.
 *
 * @returns {T} - The validated property value, or the default value if validation fails.
 */
export const getValidatedProperty = <T>(
  value: unknown,
  defaultValue: T,
  validator: (value: unknown) => value is T,
): T => (validator(value) ? value : defaultValue)
