import { Icon } from '@datasnipper/icons'
import { Card, CardContent, CardHeader, CardTitle } from '@datasnipper/ui/card'

interface ServiceItem {
  title: string
  description: string
  features: string[]
}

export type ServiceOfferingsProps = {
  title: string
  subtitle: string
  services: ServiceItem[]
}

export function ServiceOfferings({
  title,
  subtitle,
  services,
}: ServiceOfferingsProps) {
  return (
    <article className="flex justify-center">
      <div className="container">
        <h2 className="mb-2 text-center text-3xl font-medium">{title}</h2>
        <p className="mb-8 text-center text-xl">{subtitle}</p>
        <div className="mx-auto flex flex-col gap-5 px-4 py-8 md:flex-row md:justify-center lg:gap-20">
          {services.map((service, index) => (
            <Card key={index} className="max-w-[600px]">
              <CardHeader>
                <CardTitle className="text-2xl font-medium">
                  {service.title}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="mb-5 text-neutral-800">{service.description}</p>
                <ul className="space-y-2 text-neutral">
                  {service.features.map((feature, featureIndex) => (
                    <li
                      key={featureIndex}
                      className="flex items-center text-neutral-600"
                    >
                      <Icon
                        name="check"
                        className="mr-2 size-5 text-core-200"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </article>
  )
}
