import { mapAsset } from './mappers'

import { LinkPreviewCard } from '#app/components/link-preview-card/link-preview-card.js'
import { type LinkPreviewCard as LinkPreviewCardEntry } from '#app/graphql/@generated/api.ts'

export function CsLinkPreviewCard({ entry }: { entry: LinkPreviewCardEntry }) {
  return (
    <div className="mt-5">
      <LinkPreviewCard
        image={mapAsset(entry.imageConnection?.edges?.[0])}
        text={entry.main_text ?? ''}
        slug={entry.slug ?? ''}
      />
    </div>
  )
}
