import { mapAsset } from './mappers'

import {
  SlidesSection,
  SlidesSectionProps,
} from '#app/components/slides-section/slides-section'
import { SlidesSection as SlideSectionEntry } from '#app/graphql/@generated/api'

const mapEntryToProps = (entry: SlideSectionEntry): SlidesSectionProps => ({
  title: entry.title ?? '',
  subtitle: entry.subtitle ?? '',
  slides:
    entry.slides?.map(slide => ({
      title: slide?.title ?? '',
      description: slide?.description ?? '',
      image: mapAsset(slide?.imageConnection?.edges?.[0]),
      videoUrl: mapAsset(slide?.videoConnection?.edges?.[0]).src,
    })) ?? [],
})

export const CsSlidesSection = ({ entry }: { entry: SlideSectionEntry }) => (
  <SlidesSection {...mapEntryToProps(entry)} />
)
